<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right">
          <base-button icon type="secondary" @click="onCreateNewInAppNotification">
            <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
            <span class="btn-inner--text">Create In App Notification</span>
          </base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">In App Notifications</h3>
              <br>
              <b-button class="filter-button" size="sm" variant="link"
                        v-bind:class="activeTabClass('all', 'selected-tab')"
                        @click="onTabChange('all')">All
              </b-button>
              <b-button class="filter-button" size="sm" variant="link"
                        v-bind:class="activeTabClass('draft', 'selected-tab')"
                        @click="onTabChange('draft')">Draft
              </b-button>
              <b-button class="filter-button" size="sm" variant="link"
                        v-bind:class="activeTabClass('ready', 'selected-tab')"
                        @click="onTabChange('ready')">Scheduled
              </b-button>
              <b-button class="filter-button" size="sm" variant="link"
                        v-bind:class="activeTabClass('completed', 'selected-tab')"
                        @click="onTabChange('completed')">Sent
              </b-button>
              <b-button class="filter-button" size="sm" variant="link"
                        v-bind:class="activeTabClass('automated','selected-tab')"
                        @click="onTabChange('automated')">Recurring
              </b-button>
            </b-card-header>
            <el-table :data="tableData"
                      row-key="id"
                      header-row-class-name="thead-light">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column label="Status"
                               prop="status"
                               min-width="120"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge :variant="getBadgeVariant(row.status)">{{
                          getStatusLabel(row.status)
                        }}
                      </b-badge>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Schedule Date"
                               prop="nextExecutionDate"
                               min-width="200"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      {{ row.nextExecutionDate | formatISODateTime }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Sent"
                               prop="sent"
                               min-width="120"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      {{ row.sent | formatNumeric }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Open"
                               prop="ctr"
                               min-width="120"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      {{ row.ctr | formatPercentage }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Created Date"
                               prop="createdDate"
                               min-width="200"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      {{ row.createdDate | formatISODateTime }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="id">
                <template v-slot="{row}">
                  <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
                    <el-dropdown-menu class="dropdown-menu show" slot="dropdown">
                      <b-dropdown-item @click="onInAppDelete(row)" v-if="row.status === 'draft'">
                        <b-icon-trash></b-icon-trash>
                        Delete
                      </b-dropdown-item>
                      <b-dropdown-item @click="onCopyInApp(row)" v-if="row.status !== 'draft'">
                        <b-icon-files></b-icon-files>
                        Clone
                      </b-dropdown-item>
                      <b-dropdown-item @click="onEdit(row)"
                                       v-if="['draft', 'ready-to-send', 'automated'].includes(row.status)">
                        <b-icon-pencil></b-icon-pencil>
                        Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="onStatistics(row)"
                                       v-if="!['draft', 'ready-to-send'].includes(row.status)">
                        <b-icon-bar-chart></b-icon-bar-chart>
                        Statistics
                      </b-dropdown-item>
                      <b-dropdown-item @click="onCancelCampaign(row)"
                                       v-if="['inProgress', 'ready-to-send', 'ready', 'automated'].includes(row.status)">
                        <b-icon-x-circle></b-icon-x-circle>
                        Cancel
                      </b-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <b-card-footer class="justify-content-center">
              <b-row>
                <b-col lg="2">
                  <el-select @change="handlePerPage"
                             class="select-primary pagination-select"
                             v-model="pagination.perPage"
                             placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </b-col>
                <b-col lg="5" offset="1">
                  <base-pagination align="center"
                                   class="pagination-no-border"
                                   :current="pagination.currentPage"
                                   :per-page="pagination.perPage"
                                   :total="pagination.total"
                                   @change="paginationChanged($event)"
                  >
                  </base-pagination>
                </b-col>
                <b-col lg="4" class="text-right">
                  <div class="">
                    <p class="card-category">
                      Showing {{ from + 1 }} to {{ to }} of {{ pagination.total | formatNumeric }} In App Notifications
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import qs from 'qs';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Option, Select } from 'element-ui';
import clientPaginationMixin from '@/views/Tables/PaginatedTables/clientPaginationMixin';
import inAppNotificationService from '../../services/inappnotifications';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';
import swal from 'sweetalert2';
import pushNotificationService from '@/services/pushnotifications';


export default {
  name: 'InAppNotificationsList',
  mixins: [clientPaginationMixin],
  components: {
    RouteBreadCrumb,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
  },
  data: () => ({
    tableData: [],
    tableColumns: [
      {
        prop: 'name',
        label: 'Name',
        minWidth: 240,
        sortable: false
      },
    ],
    fields: ['status', 'name', 'createdDate', 'startDate', 'expireDate',
      {
        key: 'id',
        label: 'Actions',
      }],
    filter: {
      status: undefined,
      frequency: undefined
    },
    selectedStatusFilter: {},
    selectedIndex: -1,
    isClicked: true,
    activeTab: 'all',
    statusVariants: {
      done: {
        variant: 'success',
        label: 'Sent',
      },
      'ready-to-send': {
        variant: 'secondary',
        label: 'Scheduled',
      },
      'in-progress': {
        variant: 'secondary',
        label: 'In Progress',
      },
      sending: {
        variant: 'secondary',
        label: 'Sending',
      },
      draft: {
        variant: 'primary',
        label: 'Draft',
      },
    },
    tabs: {
      all: {
        frequency: 'one-off',
      },
      draft: {
        status: 'draft',
        frequency: 'one-off',
        variant: 'primary',
      },
      ready: {
        status: 'ready-to-send',
        frequency: 'one-off',
      },
      inProgress: {
        status: 'data-preparation-started,data-preparation-success,sending,in-progress',
        frequency: 'one-off',
        variant: 'info',
      },
      completed: {
        status: 'done',
        variant: 'success',
      },
      paused: {
        status: 'paused',
        buttons: [],
      },
      cancelled: { status: 'cancelled' },
      automated: {
        frequency: 'recurring',
        status: 'data-preparation-started,data-preparation-success,data-preparation-fail,sending,in-progress,sending,ready-to-send',
      },
      transactional: {
        frequency: 'transactional',
      },
      reco: {
        campaignType: 'reco',
      },
      custom: {
        campaignType: 'custom',
      }
    },
  }),
  created() {
    this.loadInAppCampaigns();
  },
  methods: {
    handlePerPage(value) {
      this.pagination.perPage = value;
      this.loadPushCampaigns();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.loadPushCampaigns();
    },
    onCreateNewInAppNotification() {
      this.$router.push('/channel/in-app-notifications/create');
    },
    search() {
      this.loadInAppCampaigns();
    },
    onTabChange(tab) {
      this.activeTab = tab;
      if (tab === 'all') {
        this.filter.status = undefined;
        this.filter.frequency = undefined;
      } else {
        this.filter.status = this.tabs[tab].status;
        this.filter.frequency = this.tabs[tab].frequency;
      }
      this.search();
    },
    activeTabClass(tab, selectedItemClass) {
      return this.activeTab === tab ? selectedItemClass : '';
    },
    getBadgeVariant(status) {
      if (status) {
        const statusVariant = this.statusVariants[status];
        return statusVariant ? statusVariant.variant : 'danger';
      }
      return 'info';
    },
    getStatusLabel(status) {
      if (status) {
        const statusVariant = this.statusVariants[status];
        return statusVariant ? statusVariant.label : 'Error';
      }
      return 'Error';
    },
    onInAppDelete(row) {
      swal.fire({
        title: `Are you sure to delete ${row.name}?`,
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      })
        .then(result => {
          if (result.value) {
            inAppNotificationService.delete(row.id)
              .then(() => {
                this.loadInAppCampaigns();
                swal.fire({
                  title: 'Deleted!',
                  text: `You deleted ${row.name}`,
                  type: 'success',
                  confirmButtonClass: 'btn btn-success btn-fill',
                  buttonsStyling: false
                });
              });
          }
        });
    },
    async loadInAppCampaigns() {
      this.filter.size = this.pagination.perPage;
      this.filter.page = this.pagination.currentPage - 1;
      const response = await inAppNotificationService.get(this.filter);
      this.pagination.total = response.total;
      this.tableData = response.results;
    },
    async onCancelCampaign(row) {
      swal.fire({
        title: `Are you sure to cancel ${row.name}?`,
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      })
        .then(result => {
          if (result.value) {
            inAppNotificationService.cancel(row.id)
              .then(() => {
                this.loadInAppCampaigns();
                swal.fire({
                  title: 'Cancelled!',
                  text: `You Cancelled ${row.name}`,
                  type: 'success',
                  confirmButtonClass: 'btn btn-success btn-fill',
                  buttonsStyling: false
                });
              });
          }
        });
    },
    async onCopyInApp(row) {
      inAppNotificationService.copy(row.id)
        .then((response) => {

          if (response.hasError) {
            this.$notify({
              message: response.message,
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'warning'
            });
          } else {
            this.$notify({
              message: 'In-App campaign copied successfully!!',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'info'
            });
            this.$router.push(`/channel/in-app-notifications/${response.id}`);
          }
        });
    },
  },
};
</script>
