<template>
  <b-row>
    <b-col cols="3">
      <span class="body1-title">Country</span>
    </b-col>
    <b-col cols="4">
      <b-dropdown variant="outline-primary" block split :text="conditions[this.rule.condition] || 'Condition'" size="sm" menu-class="w-100">
        <b-dropdown-item variant="secondary"
                         v-for="item in Object.keys(conditions)" @click="onConditionChange(item)"
                         :key="item">
          {{ conditions[item] }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="5">
      <b-dropdown class="country-menu" size="sm" block split variant="outline-primary" menu-class="w-100">
        <template #button-content>
          <div class="float-left" v-if="rule.values.length === 0">Select</div>
          <div class="float-left" v-else-if="rule.values.length === 1">
            {{ rule.values[0] }}
          </div>
          <div v-else>
            <div class="float-left">{{ rule.values[0] }}</div>
            <div class="float-right">
              <b-badge v-if="rule.values.length > 0" pill variant="secondary">+ {{ rule.values.length -1 }} country</b-badge>
            </div>
          </div>
        </template>
        <b-dropdown-form>
          <b-form-group>
            <b-form-input v-model="filterValue" size="sm" debounce="100" placeholder="Search"></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-item variant="primary" :active="rule.values.includes(item.value)"
                         v-for="item in filteredCountries" @click="onItemSelect(item.value)" :key="item.value">
          {{ item.text }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>
<script>

import countryService from '../../services/countires';

export default {
  name: 'deviceLanguageRule',
  props: ['rule'],
  data: () => ({
    countries: [],
    filteredCountries: [],
    filterValue: '',
    conditions: {
      in: 'Equal',
      ni: 'Not Equal',
    },
  }),
  created() {
    this.loadCountries();
  },
  watch: {
    filterValue(newValue) {
      this.filterValues(newValue);
    },
  },
  methods: {
    loadCountries() {
      this.countries = countryService.list();
      this.filteredCountries = countryService.list();
    },
    onConditionChange(condition) {
      this.rule.condition = condition;
    },
    filterValues(filterValue) {
      if (filterValue === '') {
        this.filteredCountries = this.countries;
      }
      this.filteredCountries = this.countries.filter(item => item.text.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
    },
    onItemSelect(pageType) {
      if (!this.rule.values.includes(pageType)) {
        this.rule.values.push(pageType);
      } else {
        const index = this.rule.values.indexOf(pageType);
        if (index > -1) {
          this.rule.values.splice(index, 1);
        }
      }
    },
  },
};
</script>
<style scoped>
.country-menu /deep/ .dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}
</style>
