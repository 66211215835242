import { xenn } from './index';

export default {
  list(params) {
    return xenn.get('/segments', { params: params });
  },
  get(id) {
    return xenn.get(`/segments/${id}`);
  },
  getMulti(ids) {
    return xenn.get(`/segments?ids=${ids.join(',')}`);
  },
  calculate(id) {
    return xenn.post(`/segments/${id}/calculate`, {});
  },
  delete(id) {
    return xenn.delete(`/segments/${id}`);
  },
  create(segment) {
    return xenn.post('/segments', segment);
  },
  dynamicSegmentCount(filterRequest) {
    return xenn.post('/dynamic-segment/count', filterRequest);
  },
  update(id, segment) {
    return xenn.put(`/segments/${id}`, segment);
  },
  postCommand(id, command) {
    return xenn.post(`/segments/${id}/${command}`);
  },
  attributes() {
    return xenn.get('/segment-attributes');
  },
};
