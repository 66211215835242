<template>
  <b-row>
    <b-col cols="5">
      <base-input v-model="selectedDate.date" :disabled="true" label="Date">
        <template slot="append">
          <b-form-datepicker button-only button-variant="primary" no-close-button
                             v-model="selectedDate.date"></b-form-datepicker>
        </template>
      </base-input>
    </b-col>
    <b-col cols="4">
      <base-input v-model="selectedDate.time" :disabled="true" label="Time">
        <template slot="append">
          <b-timepicker no-close-button button-only button-variant="primary"
                        v-model="selectedDate.time"
                        @context="onTimeChange"></b-timepicker>
        </template>
      </base-input>
    </b-col>
  </b-row>
</template>
<script>
import { DateTime } from 'luxon';

export default {
  name: 'dateTimePicker',
  props: {
    selectedDate: {},
    label: { default: 'Select Date & Time' },
    minDate: {},
  },
  methods: {
    onTimeChange(ctx) {
      if (this.selectedDate.date) {
        this.selectedDate.dateTime = DateTime.fromISO(this.selectedDate.date)
            .set(
                {
                  hour: ctx.hours || 0,
                  minutes: ctx.minutes || 0,
                  seconds: 0,
                  milliseconds: 0,
                },
            );
      }
    },
  },
};
</script>
