<template>
  <b-card>
    <b-row>
      <b-col>
        <b-row class="mb-2">
          <b-col cols="5">
            <div class="heading1-title">Paragraph</div>
          </b-col>
          <b-col cols="7">
            <div class="float-right align-items-center">
              <b-icon-arrows-move></b-icon-arrows-move>
              <b-icon-files @click="copyComponent"></b-icon-files>
              <b-icon-trash-fill @click="removeComponent"></b-icon-trash-fill>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Text">
        <b-form-textarea wrap="hard" rows="3" max-rows="3" size="sm" v-model="component.text"
                         no-resize></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <text-style-editor :text="component.style.text"></text-style-editor>
    <b-row>
      <b-col>
        <b-button v-b-toggle.collapse-advanced-text size="sm" variant="link">Advanced
          <b-icon-chevron-down></b-icon-chevron-down>
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="collapse-advanced-text" class="mt-2">
      <b-row>
        <b-col>
          <margin-editor :element="component.style"></margin-editor>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>
<script>
import textStyleEditor from './textStyleEditor';
import marginEditor from './marginEditor';

export default {
  name: 'inAppText',
  components: {
    textStyleEditor,
    marginEditor,
  },
  props: {
    component: '',
    remove: '',
    copy: '',
  },
  methods: {
    removeComponent() {
      this.remove(this.component.id);
    },
    copyComponent() {
      this.copy(this.component);
    },
  },
};
</script>
