export default {
  list() {
    return [
      {
        text: 'English ',
        value: 'en',
      },
      {
        text: 'Spanish',
        value: 'es',
      },
      {
        text: 'Afrikaans',
        value: 'af',
      },
      {
        text: 'Albanian',
        value: 'sq',
      },
      {
        text: 'Amharic',
        value: 'am',
      },
      {
        text: 'Arabic',
        value: 'ar',
      },
      {
        text: 'Armenian',
        value: 'hy',
      },
      {
        text: 'Assamese',
        value: 'as',
      },
      {
        text: 'Azeri',
        value: 'az-az',
      },
      {
        text: 'Basque',
        value: 'eu',
      },
      {
        text: 'Belarusian',
        value: 'be',
      },
      {
        text: 'Bengali - India',
        value: 'bn',
      },
      {
        text: 'Bosnian',
        value: 'bs',
      },
      {
        text: 'Bulgarian',
        value: 'bg',
      },
      {
        text: 'Burmese',
        value: 'my',
      },
      {
        text: 'Catalan',
        value: 'ca',
      },
      {
        text: 'Chinese',
        value: 'zh',
      },
      {
        text: 'Croatian',
        value: 'hr',
      },
      {
        text: 'Czech',
        value: 'cs',
      },
      {
        text: 'Danish',
        value: 'da',
      },
      {
        text: 'Divehi',
        value: 'Maldivian',
      },
      {
        text: 'Dutch - Belgium',
        value: 'nl-be',
      },
      {
        text: 'Dutch - Netherlands',
        value: 'nl-nl',
      },
      {
        text: 'Edo',
        value: '466',
      },
      {
        text: 'Estonian',
        value: 'et',
      },
      {
        text: 'FYRO Macedonia',
        value: 'mk',
      },
      {
        text: 'Faroese',
        value: 'fo',
      },
      {
        text: 'Farsi - Persian',
        value: 'fa',
      },
      {
        text: 'Filipino',
        value: '464',
      },
      {
        text: 'Finnish',
        value: 'fi',
      },
      {
        text: 'French',
        value: 'fr',
      },
      {
        text: 'Frisian - Netherlands',
        value: '462',
      },
      {
        text: 'Gaelic',
        value: 'gd',
      },
      {
        text: 'Galician',
        value: '1110',
      },
      {
        text: 'Georgian',
        value: '1079',
      },
      {
        text: 'German',
        value: 'de',
      },
      {
        text: 'Greek',
        value: 'el',
      },
      {
        text: 'Guarani - Paraguay',
        value: 'gn',
      },
      {
        text: 'Gujarati',
        value: 'gu',
      },
      {
        text: 'Hebrew',
        value: 'he',
      },
      {
        text: 'Hindi',
        value: 'hi',
      },
      {
        text: 'Hungarian',
        value: 'hu',
      },
      {
        text: 'Icelandic',
        value: 'is',
      },
      {
        text: 'Igbo - Nigeria',
        value: '470',
      },
      {
        text: 'Indonesian',
        value: 'id',
      },
      {
        text: 'Italian',
        value: 'it',
      },
      {
        text: 'Japanese',
        value: 'ja',
      },
      {
        text: 'Kannada',
        value: 'kn',
      },
      {
        text: 'Kashmiri',
        value: 'ks',
      },
      {
        text: 'Kazakh',
        value: 'kk',
      },
      {
        text: 'Khmer',
        value: 'km',
      },
      {
        text: 'Konkani',
        value: '457',
      },
      {
        text: 'Korean',
        value: 'ko',
      },
      {
        text: 'Kyrgyz - Cyrillic',
        value: '440',
      },
      {
        text: 'Lao',
        value: 'lo',
      },
      {
        text: 'Latin',
        value: 'la',
      },
      {
        text: 'Latvian',
        value: 'lv',
      },
      {
        text: 'Lithuanian',
        value: 'lt',
      },
      {
        text: 'Malay',
        value: 'ms',
      },
      {
        text: 'Malayalam',
        value: 'ml',
      },
      {
        text: 'Maltese',
        value: 'mt',
      },
      {
        text: 'Manipuri',
        value: '458',
      },
      {
        text: 'Maori',
        value: 'mi',
      },
      {
        text: 'Marathi',
        value: 'mr',
      },
      {
        text: 'Mongolian',
        value: 'mn',
      },
      {
        text: 'Nepali',
        value: 'ne',
      },
      {
        text: 'Norwegian',
        value: 'no-no',
      },
      {
        text: 'Oriya',
        value: 'or',
      },
      {
        text: 'Polish',
        value: 'pl',
      },
      {
        text: 'Portuguese',
        value: 'pt',
      },
      {
        text: 'Punjabi',
        value: 'pa',
      },
      {
        text: 'Raeto-Romance',
        value: 'rm',
      },
      {
        text: 'Romanian - Moldova',
        value: 'ro-mo',
      },
      {
        text: 'Romanian - Romania',
        value: 'ro',
      },
      {
        text: 'Russian',
        value: 'ru',
      },
      {
        text: 'Sanskrit',
        value: 'sa',
      },
      {
        text: 'Serbian - Latin',
        value: 'sr-sp',
      },
      {
        text: 'Sesotho (Sutu)',
        value: '430',
      },
      {
        text: 'Setsuana',
        value: 'tn',
      },
      {
        text: 'Sindhi',
        value: 'sd',
      },
      {
        text: 'Sinhala',
        value: 'si',
      },
      {
        text: 'Slovak',
        value: 'sk',
      },
      {
        text: 'Slovenian',
        value: 'sl',
      },
      {
        text: 'Somali',
        value: 'so',
      },
      {
        text: 'Sorbian',
        value: 'sb',
      },

      {
        text: 'Swahili',
        value: 'sw',
      },
      {
        text: 'Swedish',
        value: 'sv',
      },
      {
        text: 'Tatar',
        value: 'tt',
      },
      {
        text: 'Telugu',
        value: 'te',
      },
      {
        text: 'Thai',
        value: 'th',
      },
      {
        text: 'Tibetan',
        value: 'bo',
      },
      {
        text: 'Tsonga',
        value: 'ts',
      },
      {
        text: 'Turkish',
        value: 'tr',
      },
      {
        text: 'Turkmen',
        value: 'tk',
      },
      {
        text: 'Ukrainian',
        value: 'uk',
      },
      {
        text: 'Univalue',
        value: '0',
      },
      {
        text: 'Urdu',
        value: 'ur',
      },
      {
        text: 'Uzbek - Latin',
        value: 'uz-uz',
      },
      {
        text: 'Venda',
        value: '433',
      },
      {
        text: 'Vietnamese',
        value: 'vi',
      },
      {
        text: 'Welsh',
        value: 'cy',
      },
      {
        text: 'Xhosa',
        value: 'xh',
      },
      {
        text: 'Yiddish',
        value: 'yi',
      },
      {
        text: 'Zulu',
        value: 'zu',
      },
    ];
  },
};
