<template>
  <modal modal-classes="modal-info" modal-content-classes="bg-gradient-info" :show.sync="showStatus"
    size="responsive-fit" body-classes="p-0">
    <validation-observer ref="formValidator">
      <b-row class="segmentModal" no-gutters>
        <b-col>
          <card type="white" header-classes="bg-transparent" body-classes="px-lg-4 py-lg-2" class="border-0 mb-0">
            <b-row>
              <b-col cols="4" class="mr-auto">
                <base-input label="Segment Name" name="Segment Name" rules="required" placeholder="Segment Name"
                  success-message="Looks good!" v-model="segment.name">
                </base-input>
              </b-col>
              <b-col cols="auto" class="text-center">
                <h4>Subscriber Count</h4>
                <b-overlay :show="calculationQueueInitiated" spinner-variant="primary" spinner-type="grow" spinner-small
                  rounded="sm" style="max-width: 320px;">
                  <h3>{{ estimatedDeviceCount | formatNumeric('Pending') }}</h3>
                </b-overlay>
              </b-col>
            </b-row>
            <b-row class="mt--2">
              <b-col>
                <label class="form-control-label mb-0">Segment Rules:</label>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-row v-for="(item, index) in segment.audienceFeConfig" :key="index">
                  <b-col>
                    <b-row v-for="(filter, filterIndex) in item" :key="filter.id">
                      <b-col cols="11">
                        <RelativeDateTypeFilterElement @value-update="getDeviceCount"
                          v-if="filter.type === 'relativeDate'" :field="filter"></RelativeDateTypeFilterElement>
                        <DateTimeTypeFilterElement v-if="filter.type === 'date'" :field="filter">
                        </DateTimeTypeFilterElement>
                        <NumericTypeFilterElement @value-update="getDeviceCount" v-if="filter.type === 'numeric'"
                          :field="filter"></NumericTypeFilterElement>
                        <LookupTypeFilterElement @value-update="getDeviceCount" v-if="filter.type === 'lookup'"
                          :field="filter"></LookupTypeFilterElement>
                        <TagTypeFilterElement @value-update="getDeviceCount" v-if="filter.type === 'tag'"
                          :field="filter"></TagTypeFilterElement>
                        <LanguageTypeFilterElement @value-update="getDeviceCount"
                          v-if="filter.type === 'languageLookup'" :field="filter"></LanguageTypeFilterElement>
                        <DeviceTypeFilterElement @value-update="getDeviceCount" v-if="filter.type === 'deviceType'"
                          :field="filter"></DeviceTypeFilterElement>
                        <TimeZoneTypeFilterElement @value-update="getDeviceCount" v-if="filter.type === 'timeZone'"
                          :field="filter"></TimeZoneTypeFilterElement>
                        <ExistsTypeFilterElement @value-update="getDeviceCount" v-if="filter.type === 'exists'"
                          :field="filter"></ExistsTypeFilterElement>
                        <b-container fluid>
                          <b-row class="text-left mt--2">
                            <b-col class="bg-secondary" cols="4">
                              <b-badge class="position-relative"
                                v-show="filterIndex < item.length && item.length > 1 && filterIndex !== item.length - 1"
                                variant="primary">And
                              </b-badge>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-col>
                      <b-col cols="1">
                        <b-button @click="removeFilter(filterIndex, index)" size="xs" variant="link">
                          <b-icon-trash variant="xs"></b-icon-trash>
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-collapse class="mt-3" :visible="item.length === 0" :ref="`and.filter.collapse.${index}`"
                      :id="`and_filter_${index}`">
                      <card type="white" header-classes="bg-transparent" body-classes="px-lg-4 py-lg-2"
                        class="border-0 mb-0">
                        <template slot="header">
                          <b-row no-gutters>
                            <b-col cols="7">
                              <small>AND Filter</small>
                            </b-col>
                            <b-col v-if="segment.audienceFeConfig.length > 1 || item.length !== 0" cols="5"
                              class="text-right">
                              <b-button size="sm" variant="primary" @click="closeAvailableFilters(index)">
                                <b-icon-x-circle></b-icon-x-circle>
                              </b-button>
                            </b-col>
                          </b-row>
                        </template>
                        <b-row class="border mb-1 align-items-center bg-secondary cursor-pointer-on-hover"
                          @click="addFilter(each, index)" v-for="each in availableFilters"
                          :key="`${each.key}_${index}`">
                          <b-col cols="4">
                            <base-svg :svg="each.icon"></base-svg>
                            {{ each.fieldText }}
                          </b-col>
                          <b-col cols="7">{{ each.description }}</b-col>
                          <b-col cols="1">
                            <b-button variant="link" @click="addFilter(each, index)">
                              <b-icon-plus></b-icon-plus>
                            </b-button>
                          </b-col>
                        </b-row>
                      </card>
                    </b-collapse>
                    <div v-if="segment.audienceFeConfig.length > 1 || item.length !== 0" class="mt-3">
                      <base-button icon type="primary" size="sm" v-b-toggle="`and_filter_${index}`">
                        <span class="btn-inner--icon">
                          <b-icon-plus-circle />
                        </span>
                        <span class="btn-inner--text">Add Rule</span>
                      </base-button>
                    </div>
                    <b-row class="my-2" v-if="index < segment.audienceFeConfig.length - 1">
                      <b-col cols="1" style="display: flex; align-items: center; flex-wrap: wrap;">
                        <b-badge class="p-2" variant="primary">OR</b-badge>
                      </b-col>
                      <b-col cols="11">
                        <hr class="align-items-center">
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div v-if="canAddOr" class="mt-3">
              <base-button icon type="secondary" @click="addOrFilter">
                <span class="btn-inner--icon">
                  <b-icon-plus-circle />
                </span>
                <span class="btn-inner--text">Add OR</span>
              </base-button>
            </div>
          </card>
        </b-col>
      </b-row>
    </validation-observer>
    <template slot="footer">
      <base-button type="primary" @click="saveSegment">Save</base-button>
    </template>
  </modal>
</template>

<script>
import random from 'randomstring';
import segmentService from '../../services/segment';
import memberService from '../../services/members';
import NumericTypeFilterElement from './numericTypeFilterElement.vue';
import DateTimeTypeFilterElement from './dateTimeTypeFilterElement.vue';
import LookupTypeFilterElement from './lookupTypeFilterElement.vue';
import RelativeDateTypeFilterElement from './relativeDateTypeFilterElement.vue';
import TagTypeFilterElement from './tagTypeFilterElement.vue';
import LanguageTypeFilterElement from './languageTypeFilterElement.vue';
import DeviceTypeFilterElement from './deviceTypeFilterElement.vue';
import TimeZoneTypeFilterElement from './timeZoneTypeFilterElement.vue';
import ExistsTypeFilterElement from './existsTypeFilterElement.vue';
import { Modal } from '@/components';
import BaseSvg from '@/components/BaseSvg.vue';

export default {
  name: 'SegmentModal',
  components: {
    BaseSvg,
    ExistsTypeFilterElement,
    TimeZoneTypeFilterElement,
    DeviceTypeFilterElement,
    LanguageTypeFilterElement,
    TagTypeFilterElement,
    RelativeDateTypeFilterElement,
    LookupTypeFilterElement,
    DateTimeTypeFilterElement,
    NumericTypeFilterElement,
    Modal,
  },
  methods: {
    closeAvailableFilters(index) {
      this.$root.$emit('bv::toggle::collapse', `and_filter_${index}`);
    },
    async getSegmentDetails(id) {
      if (id) {
        const segmentDetail = await segmentService.get(id);
        segmentDetail.audienceFeConfig = JSON.parse(segmentDetail.audienceFeConfig);
        this.$set(this, 'segment', segmentDetail);
        this.getDeviceCount();
      } else {
        this.$set(this, 'segment', {
          id: undefined,
          name: '',
          type: 'elastic',
          audienceFeConfig: [[]],
          audienceConfig: {},
          identifier: random.generate({ length: 8 }),
          query: undefined,
        });
        this.$set(this, 'estimatedDeviceCount', 0);
        this.$set(this, 'calculationQueueInitiated', false);
      }
    },
    getDeviceCount() {
      if (!this.calculationQueueInitiated) {
        setTimeout(() => {
          this.getDeviceCountInternal();
          this.calculationQueueInitiated = false;
        }, 1000);
        this.calculationQueueInitiated = true;
      }
    },
    async getDeviceCountInternal() {
      const query = this.estimateCountQuery()
        .join(' OR ');
      const subscriberCount = await memberService.subscribers({
        query: btoa(query),
      });
      this.$set(this, 'estimatedDeviceCount', subscriberCount.total);
      this.segment.query = query;
    },
    openSegmentModal(id) {
      this.getSegmentDetails(id);
      this.showStatus = true;
    },
    async saveSegment() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        return;
      }
      const segmentId = this.segment.id;
      if (segmentId) {
        this.segment.query = this.estimateCountQuery()
          .join(' OR ');
        await segmentService.update(segmentId, this.segment);
        await segmentService.calculate(segmentId);
      } else {
        const result = await segmentService.create(this.segment);
        await segmentService.calculate(result.id);
      }
      this.showStatus = false;
      this.$emit('segment-modal-complete', segmentId);
    },
    closeModalWithoutNotice() {
      this.showStatus = false;
      this.$emit('segment-modal-close');
    },
    addFilter(filter, index) {
      if (this.segment.audienceFeConfig[index].filter(e => e.key === filter.key && filter.type !== 'tag').length > 0) {
        this.closeAvailableFilters(index);
        return;
      }
      const filterClone = { ...filter };
      filterClone.id = random.generate({ length: 8 });
      filterClone.value = [];
      if (typeof this.segment.audienceFeConfig[index] === 'undefined') {
        this.segment.audienceFeConfig.push([]);
      }
      this.segment.audienceFeConfig[index].push(filterClone);
      this.getDeviceCount();
      this.closeAvailableFilters(index);
      this.$root.$emit('bv::toggle::collapse', `and_filter_${index}`);
    },
    addOrFilter() {
      this.segment.audienceFeConfig.push([]);
      this.$root.$emit('bv::toggle::collapse', `and_filter_${this.segment.audienceFeConfig.length - 1}`);
    },
    removeFilter(filterIndex, index) {
      if (this.segment.audienceFeConfig.length > 1 && this.segment.audienceFeConfig[index].length === 1) {
        this.segment.audienceFeConfig.splice(index, 1);
      } else {
        this.segment.audienceFeConfig[index].splice(filterIndex, 1);
      }
      this.getDeviceCount();
    },
    estimateCountQuery() {
      const queries = [];
      for (let i = 0; i < this.segment.audienceFeConfig.length; i++) {
        const option = this.segment.audienceFeConfig[i];
        if (option.length) {
          queries.push(this.filterToSql(option.filter(o => o.query)));
        }
      }
      return queries;
    },
    filterToSql(criteria) {
      const conditions = criteria.filter(e => e.query !== undefined)
        .map(e => e.query)
        .join(' AND ');
      return criteria.length > 0 ? `${conditions}` : '*';
    },
  },
  computed: {
    canAddOr() {
      return !this.segment.audienceFeConfig.filter(g => !g.length).length;
    },
  },
  data: () => ({
    showStatus: false,
    segment: {
      id: undefined,
      name: '',
      type: 'elastic',
      audienceFeConfig: [[]],
      audienceConfig: {},
      identifier: random.generate({ length: 8 }),
      query: '*',
    },
    availableFilters: [
      {
        key: 'fs',
        fieldText: 'First Seen',
        type: 'relativeDate',
        description: 'This was the first time the device was viewed. Also known as Install Time.',
        icon: 'calendar',
        from: '',
        to: '',
        fromUnit: 'hours ago',
        toUnit: 'hours ago',
        query: undefined,
        operator: 'between',
      },
      {
        key: 'ls',
        fieldText: 'Last Seen',
        type: 'relativeDate',
        description: 'This was the last time the device was viewed.',
        icon: 'calendar',
        from: '',
        to: '',
        fromUnit: 'hours ago',
        toUnit: 'hours ago',
        query: undefined,
        operator: 'between',
      },
      {
        key: 'os',
        fieldText: 'Device Type',
        type: 'deviceType',
        description: 'Operating System of the Device',
        icon: 'phone',
        value: [],
        query: undefined,
        operator: 'one of',
      },
      {
        key: 'dl',
        fieldText: 'Device Language',
        icon: 'spellcheck',
        type: 'languageLookup',
        description: 'Device Language',
        value: [],
        query: undefined,
        operator: 'one of',
      },
      // { TODO: no render.
      //   key: 'tk',
      //   fieldText: 'Channel Type',
      //   icon: 'megaphone',
      //   type: 'channelType',
      //   description: 'Channel Type Push, Email, Sms',
      //   value: [],
      //   query: undefined,
      //   operator: 'one of',
      // },
      {
        key: 'sc',
        fieldText: 'Session Count',
        type: 'numeric',
        description: 'Total Session Count',
        icon: 'speedometer',
        from: '',
        to: '',
        query: undefined,
        operator: 'greater than',
      },
      {
        key: 'tz',
        fieldText: 'Time Zone',
        type: 'timeZone',
        icon: 'watch',
        value: [],
        query: undefined,
        description: 'Time zone of the visitor',
        operator: 'one of',
      },
      // { TODO: not ready.
      //   key: 'tags',
      //   fieldText: 'Custom Tags',
      //   icon: 'tag',
      //   type: 'tag',
      //   query: undefined,
      //   description: 'Custom Tags',
      //   operator: 'one of',
      //   dirty: true,
      // },
      {
        key: 'tm',
        fieldText: 'Test Users',
        icon: 'person-bounding-box',
        type: 'exists',
        query: undefined,
        description: 'Test Users',
        operator: 'eq',
      },
    ],
    calculationQueueInitiated: false,
    estimatedDeviceCount: 0,
  }),
};
</script>

<style scoped lang="scss">
.segmentModal {
  overflow: scroll;
  max-height: 80vh;
}

.cursor-pointer-on-hover:hover {
  cursor: pointer;
}
</style>
