<template>
  <div>
    <b-form-group :label="label">
      <b-input-group size="sm">
        <template #append>
          <b-input-group-append>
            <b-button size="sm" @click="showPickerVisibility = !showPickerVisibility"
                      :style="`background-color: ${componentStyle.color}`"
                      variant="outline-success">&nbsp;&nbsp;&nbsp;
            </b-button>
          </b-input-group-append>
        </template>
        <b-form-input size="sm" :value="componentStyle.color"></b-form-input>
      </b-input-group>
    </b-form-group>

    <div class="text-color-picker">
      <sketch-picker @input="setSelectedColor" :value="componentStyle.color"
                     v-if="showPickerVisibility"></sketch-picker>
    </div>
    <div :class="showPickerVisibility? 'text-color-picker-back-drop': ''" @click="close()"></div>
  </div>
</template>
<script>
import { Sketch } from 'vue-color';

export default {
  name: 'textColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
  props: {
    componentStyle: {},
    label: {
      type: String,
      default: 'Color',
    },
  },
  data: () => ({
    showPickerVisibility: false,
  }),
  methods: {
    setSelectedColor(color) {
      this.componentStyle.color = color.hex8;
    },
    close() {
      if (this.showPickerVisibility) {
        this.showPickerVisibility = false;
      }
    },
  },
};
</script>
<style scoped>
.text-color-picker {
  position: absolute;
  display: inline-block;
  z-index: 10000;
}

.text-color-picker-back-drop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
}
</style>
