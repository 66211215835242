<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <validation-observer ref="formValidator">
        <b-row>
          <b-col>
            <card>
              <b-row>
                <b-col cols="6">
                  <base-input label="Campaign Name" name="Campaign Name" rules="required" placeholder="Campaign Name"
                    v-model="pushNotification.name">
                  </base-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h5>Target Audience</h5>
                  <segment-list ref="selectedSegmentList" @on-segment-selected="onSegmentListSelected"
                    :selected-segments="pushNotification.segmentIds"></segment-list>
                  <segment-list ref="excludedSegmentList" @on-segment-selected="onExcludedSegmentListSelected"
                    :selected-segments="pushNotification.excludedSegmentIds"></segment-list>
                  <h5 class="mb-3" v-if="pushNotification.segmentIds.length > 0">Included Segments
                    <span v-if="pushNotification.segmentIds.length > 0">
                      ({{ pushNotification.segmentIds.length }})
                    </span>
                  </h5>
                  <b-row class="justify-content-start">
                    <b-col cols="auto" v-for="tag in pushNotification.segmentIds" :key="tag">
                      <el-tag size="small" :closable="true" :close-transition="false"
                        @close="removeSelectedSegments(tag)">
                        <segment-detail :segment-id="tag" />
                      </el-tag>
                    </b-col>
                    <b-col cols="auto">
                      <b-button @click="openSegmentList()" variant="link" size="sm">
                        <b-icon-plus-circle />
                        Add Segment
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5 class="mt-2" v-if="pushNotification.excludedSegmentIds.length > 0">
                        Excluded Segments
                        <span v-if="pushNotification.excludedSegmentIds.length > 0">
                          ({{ pushNotification.excludedSegmentIds.length }})
                        </span>
                      </h5>
                      <b-row class="justify-content-md-left">
                        <b-col cols="auto" v-for="tag in pushNotification.excludedSegmentIds" :key="tag">
                          <el-tag size="small" :closable="true" :close-transition="false"
                            @close="removeExcludedSegments(tag)">
                            <segment-detail :segment-id="tag" />
                          </el-tag>
                        </b-col>
                        <b-col v-if="pushNotification.segmentIds.length > 0">
                          <b-button @click="openExcludedSegmentList()" variant="link" size="sm">
                            <b-icon-dash-circle />
                            Exclude Segment
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h4 type="secondary" class="pt-3">
                        According to these selected criteria, this campaign is expected to be delivered approx.
                        {{ totalPushTokenCount | formatNumeric }} mobile device.
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <card>
              <h5>Message Content</h5>
              <b-row>
                <b-col cols="7">
                  <base-input size="sm" label="Title" v-model="pushNotification.title" name="Title" rules="required"
                    placeholder="Title">
                    <template slot="append">
                      <emojiPicker :o="pushNotification" field="title"></emojiPicker>
                    </template>
                  </base-input>
                  <base-input size="sm" label="Subtitle" v-model="pushNotification.subtitle" name="Subtitle"
                    placeholder="Subtitle">
                    <template slot="append">
                      <emojiPicker :o="pushNotification" field="subtitle"></emojiPicker>
                    </template>
                  </base-input>
                  <base-text-area size="sm" label="Message" v-model="pushNotification.body" name="Message"
                    rules="required" placeholder="Message" type="textarea">
                    <template slot="append">
                      <emojiPicker :o="pushNotification" field="body"></emojiPicker>
                    </template>
                  </base-text-area>
                  <base-input size="sm" label="Image" name="imageUrl" placeholder="Choose File" v-model="imageName"
                    @click="openImageUpload" @keypress="(event) => { event.preventDefault(); }">
                    <template slot="append">
                      <b-button v-if="!!(imageName.length)" class="btn btn-secondary" variant="primary"
                        @click="removeImage">Remove</b-button>
                      <b-button class="btn btn-primary" variant="primary" @click="openImageUpload">Browse</b-button>
                    </template>
                  </base-input>
                  <base-input size="sm" label="Launch URL" v-model="pushNotification.launchUrl" name="Launch URL"
                    placeholder="https:// or deeplink://">
                  </base-input>
                  <b-row>
                    <b-col>
                      <h4>Extra Parameters</h4>
                      <b-row v-for="(param, index) in pushNotification.params" :key="`rule_${index}`">
                        <b-col cols="6">
                          <base-input v-model="param.key" size="s">
                            <template slot="prepend">Key</template>
                          </base-input>
                        </b-col>
                        <b-col cols="6">
                          <base-input v-model="param.value" size="sm">
                            <template slot="prepend">Value</template>
                            <template slot="append">
                              <b-button class="float-right" variant="link" size="sm" @click="removeParameter(index)">
                                <b-icon-trash></b-icon-trash>
                              </b-button>
                            </template>
                          </base-input>
                        </b-col>
                      </b-row>
                      <b-button class="mb-3" @click="addParameter()" variant="link" size="sm">
                        <b-icon-plus-circle></b-icon-plus-circle>
                        Add Parameter
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5>UTM Parameters</h5>
                      <div class="form-row">
                        <b-col md="4">
                          <base-input size="sm" label="Source" v-model="pushNotification.utm.source" name="Source"
                            placeholder="Source"></base-input>
                        </b-col>
                        <b-col md="4">
                          <base-input size="sm" label="Medium" v-model="pushNotification.utm.medium" name="Medium"
                            placeholder="Medium"></base-input>
                        </b-col>
                        <b-col md="4">
                          <base-input size="sm" label="Campaign" v-model="pushNotification.utm.campaign" name="Campaign"
                            placeholder="Campaign"></base-input>
                        </b-col>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5>Push Settings</h5>
                      <div class="form-row">
                        <b-col cols="3">
                          <base-input type="number" size="sm" label="Badge" v-model="pushNotification.badge"
                            name="Badge" placeholder="Badge"></base-input>
                        </b-col>
                        <b-col cols="6">
                          <base-input type="number" size="sm" label="Time To Live"
                            v-model="pushNotification.expirationValue" name="Time To Live" placeholder="Time To Live">
                            <template slot="append">
                              <el-select v-model="pushNotification.expirationUnit" filterable class="no-border">
                                <el-option v-for="option in ['minutes', 'hours', 'days', 'weeks']" :key="option"
                                  :label="option" :value="option">
                                </el-option>
                              </el-select>
                            </template>
                          </base-input>
                        </b-col>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5>IOS Settings</h5>
                      <div class="form-row">
                        <b-col md="4">
                          <base-input size="sm" label="Sound" v-model="pushNotification.iosSound" name="Sound"
                            placeholder=".wav .aiff .caf"></base-input>
                        </b-col>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5>Android Settings</h5>
                      <div class="form-row">
                        <b-col md="4">
                          <base-input size="sm" label="Sound" v-model="pushNotification.androidSound" name="Sound"
                            placeholder=""></base-input>
                        </b-col>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="5">
                  <PushPreview :campaign-model="pushNotification" />
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <card>
              <h4>{{ $t('pushNotification.timing') }}</h4>
              <b-row>
                <b-col cols="6">
                  <b-form-radio-group size="sm" v-model="pushNotification.scheduleType">
                    <b-form-radio value="immediately">{{ $t('pushNotification.scheduleType.immediately') }}
                    </b-form-radio>
                    <b-form-radio value="scheduled">{{ $t('pushNotification.scheduleType.scheduled') }}</b-form-radio>
                  </b-form-radio-group>
                  <div v-show="pushNotification.scheduleType === 'scheduled'" class="pt-3">
                    <base-input v-model="pushNotification.startDate" type="datetime-local" size="sm"
                      name="Scheduled date" label="Send At" :rules="{ isDateBetween: Object.values(scheduleLimit) }"
                      :min="scheduleLimit.min" :max="scheduleLimit.max"></base-input>
                    <small>Your push notification will be sent at {{ currentTimeZone }}.</small>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <modal modal-classes="modal-info" modal-content-classes="bg-gradient-info" size="lg" body-classes="p-0"
              :show.sync="pushConfirmationModalStatus">
              <h6 slot="header" class="modal-title">{{ $t('pushNotification.confirmMessageDetails') }}</h6>
              <template slot="footer">
                <b-button v-if="!pushNotification.id" variant="secondary" @click="closeModal"
                  :disabled="isActionsDisabled">
                  <b-icon-play></b-icon-play>
                  {{ $t('pushNotification.modifyNotification') }}
                </b-button>
                <b-button v-if="!pushNotification.id" variant="primary" @click="saveAndStart(true)"
                  :disabled="isActionsDisabled">
                  <b-icon-play></b-icon-play>
                  {{ $t('pushNotification.startSendingNotification') }}
                </b-button>
              </template>
              <div class="p-4">
                <div class="text-left mb-3">
                  <b-row class="mb-1 border-bottom">
                    <h4 class="ml-3">{{ $t('pushNotification.audience') }}</h4>
                  </b-row>
                  <b-row class="mb-1 border-bottom">
                    <b-col cols="5">{{ $t('common.campaignName') }}</b-col>
                    <b-col cols="7">{{ pushNotification.name }}</b-col>
                  </b-row>
                  <b-row class="mb-1 border-bottom">
                    <b-col cols="5">{{ $t('pushNotification.estimatedReachSize') }}</b-col>
                    <b-col cols="7">{{ totalPushTokenCount | formatNumeric }}</b-col>
                  </b-row>
                  <b-row class="mb-1 border-bottom">
                    <b-col cols="5">{{ $t('pushNotification.includedSegments') }}</b-col>
                    <b-col cols="7">
                      <b-row class="justify-content-md-left">
                        <b-col cols="auto" v-for="segment in pushNotification.segmentIds" :key="segment">
                          <b-badge>
                            <segment-detail :segment-id="segment"></segment-detail>
                          </b-badge>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1 border-bottom" v-if="pushNotification.excludedSegmentIds.length > 0">
                    <b-col cols="5">{{ $t('pushNotification.excludedSegments') }}</b-col>
                    <b-col cols="7">
                      <b-row class="justify-content-md-left">
                        <b-col cols="auto" v-for="segment in pushNotification.excludedSegmentIds" :key="segment">
                          <b-badge>
                            <segment-detail :segment-id="segment"></segment-detail>
                          </b-badge>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div class="text-left mb-3">
                  <b-row class="mb-1 border-bottom">
                    <h4 class="ml-3">{{ $t('pushNotification.schedule') }}</h4>
                  </b-row>
                  <b-row class="mb-1 border-bottom">
                    <b-col cols="5">{{ $t('pushNotification.title') }}</b-col>
                    <b-col cols="7">{{ pushNotification.title }}</b-col>
                  </b-row>
                  <b-row class="mb-1 border-bottom" v-if="pushNotification.subtitle">
                    <b-col cols="5">{{ $t('pushNotification.subTitle') }}</b-col>
                    <b-col cols="7">{{ pushNotification.subtitle }}</b-col>
                  </b-row>
                  <b-row class="mb-1 border-bottom">
                    <b-col cols="5">{{ $t('pushNotification.body') }}</b-col>
                    <b-col cols="7">{{ pushNotification.body }}</b-col>
                  </b-row>
                </div>
                <div class="text-left mb-3">
                  <b-row class="mb-1 border-bottom">
                    <h4 class="ml-3">{{ $t('pushNotification.schedule') }}</h4>
                  </b-row>
                  <b-row class="mb-1 border-bottom" v-if="pushNotification.scheduleType === 'scheduled'">
                    <b-col cols="5">{{ $t('pushNotification.startSending') }}</b-col>
                    <b-col cols="7">{{ pushNotification.startDate | formatISODateTime }} {{ currentTimeZone }}</b-col>
                  </b-row>
                  <b-row class="mb-1 border-bottom" v-if="pushNotification.scheduleType === 'immediately'">
                    <b-col cols="5">{{ $t('pushNotification.startSending') }}</b-col>
                    <b-col cols="7">Immediately</b-col>
                  </b-row>
                </div>
              </div>
            </modal>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col>
                  <b-button v-if="pushNotification.id" variant="primary" @click="saveAndStart(false)">
                    {{ $t('pushNotification.save') }}
                  </b-button>
                  <b-button v-if="!pushNotification.id" size="sm" variant="primary" @click="onSubmit">
                    <b-icon-play></b-icon-play>
                    {{ $t('pushNotification.sendNotification') }}
                  </b-button>
                  <b-button v-if="!pushNotification.id" variant="link" @click="saveAndStart(false)">
                    {{ $t('pushNotification.saveAsDraft') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <imageAssetModal ref="image-asset-modal" @setImageUrl="setImageUrl" />
  </div>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu, Option, Select, Table, TableColumn, Tag } from 'element-ui';
import { DateTime } from 'luxon';
import { extend } from 'vee-validate';
import { validationMixin } from 'vuelidate';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';
import imageAssetModal from '@/components/common/imageAssetModal';
import SegmentDetail from '@/components/segments/segmentDetail.vue';
import memberService from '@/services/members';
import segmentService from '@/services/segment';
import { daysAfter, formatDatepicker, fromISO, toIsoDateTime } from '@/utils/date';
import emojiPicker from '../../components/common/emojiPicker';
import PushPreview from '../../components/pushnotifications/pushPreview';
import segmentList from '../../components/segments/segmentList';
import pushNotificationService from '../../services/pushnotifications';

export default {
  mixins: [validationMixin],
  data: () => ({
    isActionsDisabled: false,
    currentTimeZone: DateTime.now().offsetNameShort,
    pushConfirmationModalStatus: false,
    totalPushTokenCount: 0,
    parameter: {
      key: undefined,
      value: undefined,
    },
    scheduleLimit: {
      min: toIsoDateTime(DateTime.now()).substring(0, 16),
      max: daysAfter(15, DateTime.now()).substring(0, 16),
    },
    imageName: '',
    pushNotification: {
      name: '',
      title: '',
      subtitle: '',
      body: '',
      imageName: '',
      imageUrl: '',
      badge: 1,
      iosSound: undefined,
      androidSound: undefined,
      sound: undefined,
      pushType: 'image',
      frequency: 'one-off',
      campaignType: 'regular_v2',
      segmentIds: [],
      excludedSegmentIds: [],
      expirationValue: 1,
      expirationUnit: 'days',
      utm: {
        source: 'xenn',
        medium: 'push',
        campaign: undefined,
      },
      scheduleType: 'immediately',
      params: [],
      launchUrl: undefined,
      startDate: toIsoDateTime(DateTime.now()).substring(0, 16),
    },
  }),
  created() {
    this.getCampaignDetails();
  },
  methods: {
    async calculatePushTokenCount() {
      const includedSegments = await segmentService.getMulti(this.pushNotification.segmentIds);
      const excludedSegments = await segmentService.getMulti(this.pushNotification.excludedSegmentIds);
      const request = {
        query: {
          bool: {
            must: [
              { exists: { field: 'tk' } }
            ],
            should: includedSegments.results.map(segment => ({
              query_string: {
                default_field: '*',
                query: segment.query
              }
            })),
            must_not: excludedSegments.results.map(segment => ({
              query_string: {
                default_field: '*',
                query: segment.query
              }
            })),
            minimum_should_match: 1
          }
        },
        aggregation: {}
      };
      const result = await memberService.count(request);
      this.totalPushTokenCount = result.count;
    },
    closeModal() {
      this.pushConfirmationModalStatus = false;
    },
    async getCampaignDetails() {
      const campaignId = this.$route.params.id;
      if (campaignId !== 'create') {
        const notification = await pushNotificationService.getById(campaignId);
        const startDate = notification.startDate ? fromISO(notification.startDate) : DateTime.now();
        notification.startDate = formatDatepicker(startDate);
        this.pushNotification = notification;
        this.imageName = notification.imageUrl;
        this.calculatePushTokenCount();
      }
    },
    async saveAndStart(startImmediately) {
      if (this.pushNotification.scheduleType === 'immediately') {
        this.pushNotification.startDate = undefined;
      }
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        window.scrollTo(0, 0);
        return;
      }
      this.pushNotification.startDate = fromISO(this.pushNotification.startDate);
      this.isActionsDisabled = true;
      if (this.pushNotification.id) {
        await pushNotificationService.update(this.pushNotification.id, this.pushNotification);
      } else {
        if (startImmediately) {
          this.pushNotification.status = 'ready-to-send';
        }
        const result = await pushNotificationService.create(this.pushNotification);
        this.pushNotification.id = result.id;
      }
      this.isActionsDisabled = false;
      await this.$router.push('/channel/push-notifications');
    },
    async onSubmit() {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        this.pushConfirmationModalStatus = true;
      } else {
        window.scrollTo(0, 0);
      }
    },
    removeParameter(index) {
      this.pushNotification.params.splice(index, 1);
    },
    addParameter() {
      this.pushNotification.params.push({
        key: this.parameter.key,
        value: this.parameter.value,
      });
    },
    openSegmentList() {
      this.$refs.selectedSegmentList.openEvent(this.pushNotification.excludedSegmentIds);
    },
    openExcludedSegmentList() {
      this.$refs.excludedSegmentList.openEvent(this.pushNotification.segmentIds);
    },
    onSegmentListSelected(selectedIds) {
      this.pushNotification.segmentIds = selectedIds;
      this.calculatePushTokenCount();
    },
    onExcludedSegmentListSelected(selectedIds) {
      this.pushNotification.excludedSegmentIds = selectedIds;
      this.calculatePushTokenCount();
    },
    removeSelectedSegments(segment) {
      const index = this.pushNotification.segmentIds.findIndex(item => item === segment);
      if (index > -1) {
        this.pushNotification.segmentIds.splice(index, 1);
      }
      this.calculatePushTokenCount();
    },
    removeExcludedSegments(segment) {
      const index = this.pushNotification.excludedSegmentIds.findIndex(item => item === segment);
      if (index > -1) {
        this.pushNotification.excludedSegmentIds.splice(index, 1);
      }
      this.calculatePushTokenCount();
    },
    openImageUpload() {
      this.$refs['image-asset-modal'].openModal();
    },
    setImageUrl(image) {
      this.$refs['image-asset-modal'].openModal(false);
      this.imageName = image.fileName;
      this.pushNotification.imageUrl = image.path;
    },
    removeImage() {
      this.imageName = '';
      this.pushNotification.imageUrl = '';
    },
  },
  components: {
    SegmentDetail,
    RouteBreadCrumb,
    PushPreview,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tag.name]: Tag,
    emojiPicker,
    segmentList,
    imageAssetModal,
  },
};

extend('isDateBetween', (value, [min, max]) => {
  const date = DateTime.fromISO(value).valueOf();
  return date >= DateTime.fromISO(min).valueOf() && date <= DateTime.fromISO(max).valueOf();
}, {
  paramNames: ['min', 'max']
});
</script>

<style lang="scss">
.no-border .el-input__inner {
  border: 0;
  border-radius: 0;
}
</style>
