import { render, staticRenderFns } from "./emojiPicker.vue?vue&type=template&id=26def56d&scoped=true"
import script from "./emojiPicker.vue?vue&type=script&lang=js"
export * from "./emojiPicker.vue?vue&type=script&lang=js"
import style0 from "./emojiPicker.vue?vue&type=style&index=0&id=26def56d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26def56d",
  null
  
)

export default component.exports