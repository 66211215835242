<template>
  <b-row>
    <b-col cols="3">
      <span class="body1-title">Device Language</span>
    </b-col>
    <b-col cols="4">
      <b-dropdown variant="outline-primary" block split :text="conditions[this.rule.condition] || 'Condition'" size="sm" menu-class="w-100">
        <b-dropdown-item variant="secondary"
                         v-for="item in Object.keys(conditions)" @click="onConditionChange(item)"
                         :key="item">
          {{ conditions[item] }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="5">
      <b-dropdown class="locale-menu" size="sm" block split variant="outline-primary" menu-class="w-100">
        <template #button-content>
          <div class="float-left" v-if="rule.values.length === 0">Select</div>
          <div class="float-left" v-else-if="rule.values.length === 1">
            {{ rule.values[0] }}
          </div>
          <div v-else>
            <div class="float-left">{{ rule.values[0] }}</div>
            <div class="float-right">
              <b-badge v-if="rule.values.length > 0" pill variant="secondary">+ {{ rule.values.length -1 }} device language</b-badge>
            </div>
          </div>
        </template>
        <b-dropdown-form>
          <b-form-group>
            <b-form-input v-model="filterValue" size="sm" debounce="100" placeholder="Search"></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-item variant="primary" :active="rule.values.includes(item.code)"
                         v-for="item in filteredLanguages" @click="onItemSelect(item.code)" :key="item.code">
          {{ item.locale }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>
<script>
import localeService from '../../services/locales';

export default {
  name: 'deviceLanguageRule',
  props: ['rule'],
  data: () => ({
    languages: ['English', 'Turkish', 'categoryPage'],
    filteredLanguages: [],
    filterValue: '',
    conditions: {
      in: 'Equal',
      ni: 'Not Equal',
    },
  }),
  watch: {
    filterValue(newValue) {
      this.filterValues(newValue);
    },
  },
  created() {
    this.loadLocales();
  },
  methods: {
    loadLocales() {
      this.languages = localeService.list();
      this.filteredLanguages = localeService.list();
    },
    onConditionChange(condition) {
      this.rule.condition = condition;
    },
    filterValues(filterValue) {
      if (filterValue === '') {
        this.filteredLanguages = this.languages;
      }
      this.filteredLanguages = this.languages.filter(item => item.locale.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
    },
    onItemSelect(locale) {
      if (!this.rule.values.includes(locale)) {
        this.rule.values.push(locale);
      } else {
        const index = this.rule.values.indexOf(locale);
        if (index > -1) {
          this.rule.values.splice(index, 1);
        }
      }
    },
  },
};
</script>
<style scoped>
.locale-menu /deep/ .dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}
</style>
