<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-form-group label="Format">
          <b-button size="sm" @click="setAttribute('fontWeight', 'bold')"
                    :variant="text.fontWeight === 'bold'? 'primary': 'outline-primary'">
            <b-icon-type-bold></b-icon-type-bold>
          </b-button>
          <b-button size="sm" @click="setAttribute('textDecoration', 'underline')"
                    :variant="text.textDecoration === 'underline'? 'primary': 'outline-primary'">
            <b-icon-type-underline></b-icon-type-underline>
          </b-button>
          <b-button size="sm" @click="setAttribute('fontStyle', 'italic')"
                    :variant="text.fontStyle === 'italic'? 'primary': 'outline-primary'">
            <b-icon-type-italic></b-icon-type-italic>
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="8">
        <b-form-group label="Font Family">
          <b-form-select size="sm" v-model="text.fontFamily"
                         :options="fontFamilies"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group label="Alignment">
          <b-button size="sm" @click="setAttribute('align', 'left')"
                    :variant="text.align === 'left'? 'primary': 'outline-primary'">
            <b-icon-text-left></b-icon-text-left>
          </b-button>
          <b-button size="sm" @click="setAttribute('align', 'center')"
                    :variant="text.align === 'center'? 'primary': 'outline-primary'">
            <b-icon-text-center></b-icon-text-center>
          </b-button>
          <b-button size="sm" @click="setAttribute('align', 'right')"
                    :variant="text.align === 'right'? 'primary': 'outline-primary'">
            <b-icon-text-right></b-icon-text-right>
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <text-color-picker :component-style="text"></text-color-picker>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Size">
          <b-form-select size="sm" v-model="text.fontSize"
                         :options="fontSizes"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import textColorPicker from './textColorPicker';

export default {
  name: 'textStyleEditor',
  components: { textColorPicker },
  props: ['text'],
  data() {
    return {
      fontSizes: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72],
      fontFamilies: ['System Font (Default)',
        'Concert One',
        'Fira Sans',
        'Inter',
        'Lato',
        'Lora',
        'Merriweather',
        'Montserrat',
        'Mukta',
        'Noto Sans',
        'Noto Sans TC',
        'Noto Serif',
        'Nunito',
        'Nunito Sans',
        'Open Sans',
        'Open Sans Condensed',
        'Oswald',
        'Playfair Display',
        'Poppins',
        'Prompt',
        'PT Sans',
        'Raleway',
        'Roboto',
        'Roboto Condensed',
        'Roboto Mono',
        'Rubik',
        'Source Sans Pro',
        'Ubuntu',
        'Work Sans'],
    };
  },
  methods: {
    setAttribute(name, value) {
      const currentValue = this.text[name];
      if (currentValue === value) {
        this.text[name] = undefined;
      } else {
        this.text[name] = value;
      }
    },
  },
};
</script>
