<template>
  <b-row>
    <b-col class="bg-info pt-2" cols="4">
      <b-img :src="field.icon"></b-img>
      <span class="pl-2">{{ field.fieldText }}</span>
    </b-col>
    <b-col cols="2">
      <b-form-select
        :options="field.operators"
        :value="field.operator"
      ></b-form-select>
    </b-col>
    <b-col cols="5">
      <b-row>
        <b-col cols="7">
          <b-form-datepicker
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="filter.value" class="mr-2"></b-form-datepicker>
        </b-col>
        <b-col cols="5">
          <b-form-timepicker v-model="filter.time"></b-form-timepicker>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import 'flatpickr/dist/flatpickr.min.css';
import { now } from '../../utils/date';

export default {
  name: 'DateTimeTypeFilterElement',
  props: ['field'],
  data: () => ({
    filter: {
      value: now(),
      time: '12:00',
    },
  }),
};
</script>
