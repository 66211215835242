<template>
  <b-row>
    <b-col cols="3">
      <span class="body1-title">Page Type</span>
    </b-col>
    <b-col cols="4">
      <b-dropdown variant="outline-primary" block split :text="conditions[this.rule.condition] || 'Condition'" size="sm"
                  menu-class="w-100">
        <b-dropdown-item variant="secondary"
                         v-for="item in Object.keys(conditions)" @click="onConditionChange(item)"
                         :key="item">
          {{ conditions[item] }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="5">
      <b-dropdown size="sm" block split variant="outline-primary" menu-class="w-100">
        <template #button-content>
          <div class="float-left" v-if="rule.values.length === 0">Select</div>
          <div class="float-left" v-else-if="rule.values.length === 1">
            {{ rule.values[0] }}
          </div>
          <div v-else>
            <div class="float-left">{{ rule.values[0] }}</div>
            <div class="float-right">
              <b-badge v-if="rule.values.length > 0" pill variant="secondary">+ {{ rule.values.length - 1 }} page type
              </b-badge>
            </div>
          </div>
        </template>
        <b-dropdown-item variant="primary" :active="rule.values.includes(item.value)"
                         v-for="item in pageTypes" @click="onItemSelect(item.value)" :key="item.value">
          {{ item.value }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>
<script>

import lookupsService from '../../services/lookups';

export default {
  name: 'pageTypeRule',
  components: {
    lookupsService,
  },
  props: ['rule'],
  data: () => ({
    pageTypes: [],
    conditions: {
      in: 'Equal',
      ni: 'Not Equal',
    },
  }),
  created() {
    this.loadPageTypes();
  },
  methods: {
    async loadPageTypes() {
      const response = await lookupsService.list({ type: 'pageType' });
      this.pageTypes = response;
    },
    onConditionChange(condition) {
      this.rule.condition = condition;
    },
    onItemSelect(pageType) {
      if (!this.rule.values.includes(pageType)) {
        this.rule.values.push(pageType);
      } else {
        const index = this.rule.values.indexOf(pageType);
        if (index > -1) {
          this.rule.values.splice(index, 1);
        }
      }
    },
  },
};
</script>
