<template>
  <span><b-icon-pie-chart-fill></b-icon-pie-chart-fill> &nbsp;{{ segment.name }}</span>
</template>
<script>
import segmentService from '../../services/segment';

export default {
  name: 'SegmentDetail',
  props: ['segmentId'],
  created() {
    this.loadSegment();
  },
  data() {
    return {
      segment: { },
    };
  },
  methods: {
    async loadSegment() {
      this.segment = await segmentService.get(this.segmentId);
    },
  }
};
</script>
