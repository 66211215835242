import random from 'randomstring';

export default {
  name: 'inAppNotificationsPreview',
  render(meta) {
    const fonts = [];
    let htmlContent = '';
    let bodyStyle = '';
    const previewComponentStyles = [];
    for (let i = 0; i < meta.designElements.length; i++) {
      const element = meta.designElements[i];
      let content = {
        css: '',
        html: '',
      };
      if (element.type === 'LinkButton') {
        content = this.generateButtonLink(element, fonts);
      } else if (element.type === 'Image') {
        content = this.generateImage(element);
      } else if (element.type === 'Text') {
        content = this.generateText(element, fonts);
      }
      bodyStyle = meta.position === 'full' ? '.flex-container{align-content:center;justify-content:space-around;height:100%} .image-container{flex-basis:100%} .modal-content-container{overflow-y:hidden;overflow-x:hidden} .modal-container{height:100%} .modal-container .modal{background:inherit;overflow:hidden;height:100%}' : '';
      htmlContent += content.html;
      previewComponentStyles.push(content.css);
    }
    return `<html style="--safe-area-inset-top:30px; --safe-area-inset-bottom:10px; --safe-area-inset-right:0px; --safe-area-inset-left:0px;">
                <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=${[...new Set(fonts)].join('|')}">
                    <style>
                     * {
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        }
                     h1 {
                        font-weight: 400;
                     }
                     body {
                       font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
                       overflow: hidden;
                       cursor: pointer;
                       padding: 24px;
                       margin: 0;
                       background-color: ${meta.color};
                     }
                     .flex-container {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                     }
                     .flex-container > * {
                       margin-top: 8px;
                       margin-bottom: 8px;
                     }
                     /* Image only for Fullscreen and Modal */
                     .image-container {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                     }

                    </style>
                    <style>
                      ${bodyStyle}
                      ${previewComponentStyles.join(' ')}
                      .close-button-in-app {
                         top: -12px;
                         right: -8px;
                         min-width: 48px;
                         min-height: 48px;
                         position: absolute;
                         display: flex;
                         justify-content: center;
                         flex-direction: column;
                         align-items: center;
                         z-index: 2;
                         margin-top: 0px;
                         margin-right: 0px;
                         margin-bottom: 0px;
                         margin-left: 0px;
                      }
                      .close-button-in-app > svg,
                      .close-button-in-app > img {
                        width: ${meta.closeButton.width}px;
                        height: ${meta.closeButton.height}px;
                      }
                    </style>
                    <script>
                    function postMessage(message) {
                      var encodedMsg = JSON.stringify(message);
                      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosListener)
                          window.webkit.messageHandlers.iosListener.postMessage(encodedMsg);
                      else if (window.XennAndroid)
                          window.XennAndroid.postMessage(encodedMsg);
                      else if (window.parent) {
                          window.parent.postMessage(message, "*");
                      }
                    }
                    function getPageDimensions() {
                      var finalComponent = document.getElementById('final-component');

                      var modalContentContainer = document.querySelector('.modal-content-container');

                      var modalContentContainerBox = modalContentContainer.getBoundingClientRect();
                      return {
                          outerContentContainerRect: JSON.parse(JSON.stringify(modalContentContainerBox)),
                          box: {
                              height: finalComponent.getBoundingClientRect().top + modalContentContainerBox.top
                          },
                      };
                    }
                    window.onload = function() {
                      var message = {'eventType': 'renderCompleted', 'data': getPageDimensions()};
                      postMessage(message);
                    };

                    </script>
                </head>
                <body>
                    <div class="modal-content-container">
                        <div class="modal-container">
                          <div class="modal">
                             <div class="flex-container">
                                ${htmlContent}
                                <div class="close-button close-button-in-app" onclick="postMessage({'eventType': 'close'})">
                                   <svg width="10" height="10" preserveAspectRatio="none" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7.80309 1.14768C8.06564 0.885137 8.06564 0.459453 7.80309 0.196909C7.54055 -0.0656362 7.11486 -0.0656362 6.85232 0.196909L4 3.04923L1.14768 0.196909C0.885137 -0.0656362 0.459453 -0.0656362 0.196909 0.196909C-0.0656362 0.459453 -0.0656362 0.885137 0.196909 1.14768L3.04923 4L0.196909 6.85232C-0.0656362 7.11486 -0.0656362 7.54055 0.196909 7.80309C0.459453 8.06564 0.885137 8.06564 1.14768 7.80309L4 4.95077L6.85232 7.80309C7.11486 8.06564 7.54055 8.06564 7.80309 7.80309C8.06564 7.54055 8.06564 7.11486 7.80309 6.85232L4.95077 4L7.80309 1.14768Z" fill="${meta.closeButton.color}"></path>
                                   </svg>
                                </div>
                                 <div id="final-component"></div>
                             </div>
                          </div>
                        </div>
                    </div>
                </body>
            </html>`;
  },
  generateText(element, fonts) {
    const elementId = `${element.type}-${random.generate({
      length: 10,
      charset: 'alphabetic',
    })}`;
    const elementTextStyle = element.style.text;
    const styleBuilder = [];
    this.setMargins(styleBuilder, element.style);

    if (elementTextStyle.fontStyle) {
      styleBuilder.push(`font-style:${elementTextStyle.fontStyle}`);
    }
    if (elementTextStyle.fontWeight) {
      styleBuilder.push(`font-weight:${elementTextStyle.fontWeight}`);
    }
    if (elementTextStyle.textDecoration) {
      styleBuilder.push(`text-decoration:${elementTextStyle.textDecoration}`);
    }
    if (elementTextStyle.align) {
      styleBuilder.push(`text-align:${elementTextStyle.align}`);
    }
    styleBuilder.push(`color:${elementTextStyle.color}`);
    styleBuilder.push(`font-size:${elementTextStyle.fontSize}px`);
    styleBuilder.push(`font-family:${elementTextStyle.fontFamily}`);
    const styleClass = `#${elementId} { ${styleBuilder.join(';')} }`;
    fonts.push(elementTextStyle.fontFamily);
    return {
      html: `<div class="title-container">
                 <h1 id="${elementId}">${element.text.replaceAll('\n', '<br>')}</h1>
               </div>`,
      css: styleClass,
    };
  },
  generateImage(element) {
    const defaultImage = 'https://s.xenn.io/cc2abbcb-6a68-4098-9718-10c286a4aa2c/images/5fe8b9cb-720c-4b1c-92f3-27fb4c13e109.png';
    const randomId = random.generate({
      length: 10,
      charset: 'alphabetic',
    });
    const elementId = `${element.type}-${randomId}`;
    const imgElementId = `${element.type}-img-${randomId}`;
    const styleBuilder = [];
    styleBuilder.push('flex-shrink: 9999');
    styleBuilder.push('min-height: 10px');
    styleBuilder.push('background-size: contain');
    styleBuilder.push('background-position: center');
    styleBuilder.push('background-repeat: no-repeat');
    styleBuilder.push(`background-image: url("${element.url || defaultImage}")`);

    this.setMargins(styleBuilder, element.style);

    const imgStyleBuilder = [];
    imgStyleBuilder.push('width: 100%');
    imgStyleBuilder.push('min-height: 10px');
    imgStyleBuilder.push('opacity: 0');

    const styleClass = `#${elementId} { ${styleBuilder.join(';')} }`;
    const imgStyleClass = `#${imgElementId} { ${imgStyleBuilder.join(';')} }`;

    return {
      html: `<div id="${elementId}" class="image-container">
                <a onclick="postMessage({'eventType': 'linkClicked', 'link': '${element.link}', 'type': 'image', 'value': '${element.text}', 'element': '${elementId}'})">
                    <img id="${imgElementId}" src="${element.url || defaultImage}"/>
                </a>
            </div>`,
      css: `${styleClass} ${imgStyleClass}`,
    };
  },
  generateButtonLink(element, fonts) {
    const elementId = `${element.type}-${random.generate({
      length: 10,
      charset: 'alphabetic',
    })}`;
    const buttonStyle = element.style;
    const elementTextStyle = buttonStyle.text;

    const styleBuilder = [];
    styleBuilder.push('width: 100%');
    styleBuilder.push('height: auto');
    styleBuilder.push('padding: 12px');
    styleBuilder.push(`color:${elementTextStyle.color}`);
    styleBuilder.push(`font-size:${elementTextStyle.fontSize}px`);
    styleBuilder.push(`background-color:${buttonStyle.color}`);
    styleBuilder.push(`font-family:${elementTextStyle.fontFamily}`);

    if (elementTextStyle.fontStyle) {
      styleBuilder.push(`font-style:${elementTextStyle.fontStyle}`);
    }
    if (elementTextStyle.fontWeight) {
      styleBuilder.push(`font-weight:${elementTextStyle.fontWeight}`);
    }
    if (elementTextStyle.textDecoration) {
      styleBuilder.push(`text-decoration:${elementTextStyle.textDecoration}`);
    }

    if (buttonStyle.border) {
      styleBuilder.push(`border-color: ${buttonStyle.border.color}`);
      styleBuilder.push(`border-radius: ${buttonStyle.border.radius}px`);
      styleBuilder.push('border-style: solid');
      styleBuilder.push(`border-width: ${buttonStyle.border.weight}px`);
    }

    this.setMargins(styleBuilder, element.style);

    if (elementTextStyle.align) {
      styleBuilder.push(`text-align:${elementTextStyle.align}`);
    }

    const styleClass = `#${elementId} { ${styleBuilder.join(';')} }`;
    fonts.push(elementTextStyle.fontFamily);

    return {
      html: `<div class="button-container">
                 <button type="button" id="${elementId}" onclick="postMessage({'eventType': 'linkClicked', 'type': 'button', 'link': '${element.link}', 'value': '${element.text}', 'element': '${elementId}'})">${element.text}</button>
               </div>`,
      css: styleClass,
    };
  },
  setMargins(styleBuilder, style) {
    if (style.margin) {
      styleBuilder.push(`margin-top: ${style.margin.top}px`);
      styleBuilder.push(`margin-right: ${style.margin.right}px`);
      styleBuilder.push(`margin-bottom: ${style.margin.bottom}px`);
      styleBuilder.push(`margin-left: ${style.margin.left}px`);
    }
  },
};
