<template>
  <modal modal-classes="modal-info" modal-content-classes="bg-gradient-info" :show.sync="showStatus" size="xl"
    body-classes="p-0">
    <b-card title="Card Title" no-body class="mb-0 fix-height">
      <b-overlay :show="showPreloader">
        <template #overlay>
          <b-icon icon="circle-fill" font-scale="3" animation="throb" />
        </template>
        <b-card-body>
          <b-row>
            <b-col cols="auto">
              <b-nav pills vertical>
                <b-nav-item v-for="tab in tabs" :key="tab.name" :active="activeTab === tab.name"
                  @click="activeTab = tab.name" class="mt-2">
                  <b-icon class="mr-2" :icon="tab.icon" />{{ tab.label }}
                </b-nav-item>
              </b-nav>
            </b-col>
            <b-col>
              <div v-if="activeTab === 'library'">
                <b-row>
                  <b-col>
                    <h2 class="mb-0">Image Library</h2>
                    <p>Select image to use in template</p>
                  </b-col>
                  <b-col cols="4">
                    <select class="custom-select" v-model="query.sortDirection">
                      <option value="desc">Created Date Desc</option>
                      <option value="asc">Created Date Asc</option>
                    </select>
                  </b-col>
                </b-row>
                <b-container>
                  <b-row>
                    <b-col v-for="x in images" :key="x.id" cols="3" class="p-1">
                      <div class="image-wrapper" :class="{ active: selectedImage.id === x.id }"
                        @click="selectedImage = x">
                        <b-img :src="x.path" />
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
                <b-row>
                  <b-col v-if="selectedImage.fileName" cols="auto">
                    <p class="mt-3 mb-0">Selected: <b><i>{{ selectedImage.fileName }}</i></b></p>
                  </b-col>
                  <b-col>
                    <b-pagination v-model="query.page" :total-rows="total" :per-page="query.size" @change="onPageChange"
                      align="right" class="mt-3" />
                  </b-col>
                </b-row>
              </div>
              <div v-if="activeTab === 'upload'">
                <h2 class="mb-0">Upload from computer</h2>
                <p>Choose a file or drop it here</p>
                <b-row>
                  <b-col xl="6" lg="8" md="12">
                    <b-form-file v-model="file" drop-placeholder="Drop file here" />
                  </b-col>
                </b-row>
              </div>
              <div v-if="activeTab === 'import'">
                <h2 class="mb-0">Import from URL</h2>
                <p>Paste a URL and use the image</p>
                <b-row>
                  <b-col xl="6" lg="8" md="12">
                    <b-form-input v-model="url" placeholder="Type or past URL here" />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>
    <template slot="footer">
      <base-button type="primary" @click="save">Select</base-button>
    </template>
  </modal>
</template>

<script>
import { Modal } from '@/components';
import assetService from '@/services/asset';

export default {
  name: 'ImageAssetModal',
  components: {
    Modal,
  },
  mounted() {
    this.load();
  },
  watch: {
    'query.sortDirection'() {
      this.load(0);
    },
    activeTab(val) {
      this.selectedImage = {};

      if (val === 'library') {
        this.query.page = 0;
        this.load(0);
      }
    },
    file() {
      this.uploadFile();
    },
    url() {
      this.importFromUrl();
    },
  },
  methods: {
    openModal(status = true) {
      this.showStatus = status;
    },
    async importFromUrl() {
      this.showPreloader = true;

      const uploaded = await assetService.importFrom({ path: this.url });

      this.selectedImage = uploaded;
      this.showPreloader = false;
      this.save();
    },
    async uploadFile() {
      this.showPreloader = true;

      const uploaded = await assetService.post({
        fileType: 'image',
        folderName: 'images',
        file: this.file,
      });

      this.selectedImage = uploaded;
      this.showPreloader = false;
      this.save();
    },
    async load(page) {
      this.showPreloader = true;

      const response = await assetService.list({
        ...this.query,
        page: page ?? this.query.page,
      });
      this.$set(this, 'images', response.results);
      this.$set(this, 'pageCount', response.pageCount);
      this.$set(this, 'total', response.total);

      this.showPreloader = false;
    },
    async deleteImage(asset) {
      const result = confirm(`Are you sure to delete ${asset.fileName}?`);
      if (result) {
        await assetService.delete(asset.id);
        this.load((this.query.page - 1) || 0);
        this.selectedImage = {};
      }
    },
    onPageChange(expected) {
      this.load(expected - 1);
    },
    save() {
      this.$emit('setImageUrl', this.selectedImage);
    },
  },
  data: () => ({
    tabs: [{
      name: 'library',
      label: 'Library',
      icon: 'bookmarks',
    }, {
      name: 'upload',
      label: 'Upload',
      icon: 'cloud-upload',
    }, {
      name: 'import',
      label: 'Import',
      icon: 'cloud-download',
    }],
    selectedImage: {},
    showStatus: false,
    activeTab: 'library',
    url: undefined,
    file: undefined,
    fileName: undefined,
    images: [],
    total: 0,
    pageCount: 0,
    showPreloader: false,
    query: {
      page: 0,
      size: 8,
      sortDirection: 'desc',
    },
  }),
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  height: 100%;
  background: #f8f9fa;
  border: 3px solid #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 170px;
  height: 170px;
  max-height: 170px;
  cursor: pointer;

  &.active {
    border-color: #6809b5;
    border-width: 5px;
  }

  &:hover {
    border-color: #d782d9;

    .image-action {
      display: block;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .image-action {
    display: none;
    width: 100%;
    text-align: center;
    margin-top: -24px;
    background: #e9ecef;

    .image-remove {
      padding: 0 5px;
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }
}
</style>
