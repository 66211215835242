<template>
  <b-container fluid class="text-center mb-2">
    <b-row class="text-left">
      <b-col class="bg-info pt-2" cols="4">
        <b-img :src="field.icon"></b-img>
        <span class="pl-2">{{ field.fieldText }}</span>
      </b-col>
      <b-col cols="2">
        <b-form-select @change="onValueChange" :options="operators" :value="operator"
          v-model="operator"></b-form-select>
      </b-col>
      <b-col cols="6">
        <b-dropdown :id="`multi_select_${field.id}`" variant="primary" block split @change="onValueChange">
          <template #button-content>
            <div class="float-left" v-if="value.length === 0">Select</div>
            <div class="float-left" v-else-if="value.length === 1">
              {{ value[0].text }}
            </div>
            <div v-else>
              <div class="float-left">{{ value[0].text }}</div>
              <div class="float-right">
                <b-badge v-if="value.length > 0" pill variant="secondary">+ {{ value.length - 1 }}
                  {{ field.fieldText }}
                </b-badge>
              </div>
            </div>
          </template>
          <div class="dropdown-form-scrollable">
            <b-dropdown-form class="dropdown-form-scrollable">
              <b-form-group>
                <b-form-input v-model="filterValue" size="sm" debounce="100" placeholder="Search"></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-item variant="secondary" :active="value.includes(item)" v-for="item in filteredOptions"
              @click="onItemSelect(item)" :key="item.value">
              {{ item.text }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'LookupTypeFilterElement',
  props: ['field'],
  data: () => ({
    value: [],
    filterValue: '',
    filteredOptions: [],
    query: undefined,
    operator: 'one of',
    operators: ['one of', 'none of'],
  }),
  watch: {
    filterValue(newValue) {
      this.filterValues(newValue);
    },
  },
  created() {
    this.filteredOptions = this.field.options;
  },
  methods: {
    onValueChange() {
      if (this.value.length > 0) {
        const valueMap = this.value.map(e => (this.operator === 'none of' ? `NOT "${e.value}"` : `"${e.value}"`));
        this.field.query = `(${this.field.key}:(${valueMap.join(' OR ')})) `;
        this.$emit('value-update');
      }
    },
    filterValues(filterValue) {
      if (filterValue === '') {
        this.filteredOptions = this.field.options;
      }
      this.filteredOptions = this.field.options.filter(item => item.text.toLowerCase()
        .indexOf(filterValue.toLowerCase()) > -1);
    },
    onItemSelect(optionValue) {
      if (!this.value.includes(optionValue)) {
        this.value.push(optionValue);
      } else {
        const index = this.value.indexOf(optionValue);
        if (index > -1) {
          this.value.splice(index, 1);
        }
      }
      this.onValueChange();
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-form-scrollable {
  max-height: 400px;
  overflow-y: auto;

  .b-dropdown-form {
    padding: 0.25rem 0.5rem;
  }

  .form-group {
    margin-bottom: 0;
  }
}
</style>
