<template>
  <b-card>
    <b-row>
      <b-col>
        <b-row class="mb-2">
          <b-col cols="5">
            <div class="heading1-title"> Image</div>
          </b-col>
          <b-col cols="7">
            <div class="float-right align-items-center">
              <b-icon-arrows-move></b-icon-arrows-move>
              <b-icon-files @click="copyComponent"></b-icon-files>
              <b-icon-trash-fill @click="removeComponent"></b-icon-trash-fill>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group label="Image">
          <template #append>
            <div class="upload-btn-wrapper">
              <span>&nbsp;</span>
              <button class="btn-primary rounded-3">
                <b-icon-upload></b-icon-upload>
                Upload
              </button>
              <input type="file" @change="onFileUpdate" name="uploaded" accept="image/jpeg, image/gif"/>
            </div>
          </template>
          <b-form-input id="fileUploadInput" :state="fileUploadState" size="sm" v-model="image" @blur="importFromUrl"
                        placeholder="Paste an image url to here"></b-form-input>
          <b-form-invalid-feedback id="fileUploadInput-feedback">
            {{ fileUploadErrorText }}
          </b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-show="fileName !== undefined">
          <b-form-tag @remove="removeImage()" :title="fileName" class="mt-2">{{ fileName }}</b-form-tag>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-show="uploadIsInProgress" class="mt-2">
          <b-progress :value="progress" variant="success" striped></b-progress>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Image Link">
          <b-form-input size="sm" v-model="component.link"
                        placeholder="https://your-domain.com">

          </b-form-input>
          <b-form-invalid-feedback id="campaign-link-feedback">
            This is a required field and must be at least 3 characters.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button v-b-toggle.collapse-image-margin size="sm" variant="link">Advanced
          <b-icon-chevron-down></b-icon-chevron-down>
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="collapse-image-margin" class="mt-2">
      <b-row>
        <b-col>
          <margin-editor :element="component.style"></margin-editor>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>
<script>

import marginEditor from './marginEditor';
import assetService from '../../services/asset';

export default {
  name: 'inAppImage',
  components: {
    marginEditor,
  },
  props: {
    component: '',
    remove: '',
    copy: '',
  },
  data: () => ({
    fileUploadState: undefined,
    fileUploadErrorText: '',
    image: undefined,
    file: undefined,
    fileName: undefined,
    uploadIsInProgress: false,
    progress: 40,
  }),
  methods: {
    async uploadFile() {
      this.uploadIsInProgress = true;
      this.progress = 60;
      const uploaded = await assetService.post({
        fileType: 'image',
        folderName: 'images',
        file: this.file,
      });
      this.handleFileUploadResult(uploaded, this.fileName);
      this.image = undefined;
    },
    handleFileUploadResult(uploaded) {
      if (uploaded.hasError) {
        this.fileUploadState = false;
        // eslint-disable-next-line prefer-destructuring
        this.fileUploadErrorText = uploaded.message.params[0];
      } else {
        this.fileUploadState = true;
        this.fileUploadErrorText = undefined;
        this.component.url = uploaded.path;
        this.progress = 80;
      }
      setTimeout(() => {
        this.uploadIsInProgress = false;
        this.progress = 40;
      }, 2000);
    },
    async importFromUrl() {
      if (this.image && this.image !== '') {
        this.progress = 60;
        this.uploadIsInProgress = true;
        const uploaded = await assetService.importFrom({ path: this.image });
        this.handleFileUploadResult(uploaded, undefined);
        this.fileName = undefined;
      }
    },
    removeImage() {
      this.image = undefined;
      this.fileName = undefined;
      this.file = undefined;
      this.component.url = undefined;
    },
    removeComponent() {
      this.remove(this.component.id);
    },
    copyComponent() {
      this.copy(this.component);
    },
    onFileUpdate(event) {
      const file = event.target.files[0];
      const fileName = file.name;
      this.$set(this, 'fileName', fileName);
      this.$set(this, 'file', file);
      this.uploadFile();
    },
  },
};
</script>
<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
