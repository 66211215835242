<template>
  <b-container fluid class="text-center">
    <b-row class="text-left">
      <b-col class="bg-secondary" cols="4">
        <b-row class="align-items-center">
          <b-col cols="auto" class="mr-auto">
            <base-svg :svg="field.icon"></base-svg>
            <span class="pl-2">{{ field.fieldText }}</span>
          </b-col>
          <b-col cols="auto">
            <b-form-select class="mt-0" :options="customerTagsNames" v-model="field.key" @change="onTagChange">
            </b-form-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8">
        <b-row v-show="field.internalKey === 'ln' || field.internalKey === 'db'">
          <b-col cols="3">
            <b-form-select :options="['greater than', 'less than', 'between']" :value="field.operator"
                           v-model="field.operator" @change="onValueChange">
            </b-form-select>
          </b-col>
          <b-col :cols="4">
            <b-input placeholder="From" no-wheel v-model="field.from" type="number"
                     v-show="(this.field.operator === 'greater than') || (this.field.operator === 'between')"
                     @blur="onValueChange">
            </b-input>
          </b-col>
          <b-col :cols="4">
            <b-input placeholder="To" no-wheel v-model="field.to" type="number"
                     v-show="(this.field.operator === 'less than') || (this.field.operator === 'between')"
                     @blur="onValueChange">
            </b-input>
          </b-col>
        </b-row>
        <b-row v-show="field.internalKey === 'ar' || field.internalKey === 'str'">
          <b-col cols="3">
            <b-form-select :options="['one of', 'none of']" :value="field.operator" v-model="field.operator"
                           @change="onValueChange">
            </b-form-select>
          </b-col>
          <b-col :cols="9">
            <b-form-tags tag-variant="primary" v-model="field.values" @blur="onValueChange"></b-form-tags>
          </b-col>
        </b-row>
        <b-row v-show="field.internalKey === 'b'">
          <b-col>
            <b-form-checkbox v-model="field.checked" switch @change="onValueChange">
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import memberService from '../../services/members';

export default {
  name: 'TagTypeFilterElement',
  components: {},
  props: ['field'],
  data: () => ({
    selectedTag: undefined,
    customerTags: [],
    customerTagsNames: [],
    typeOperators: {
      ln: 'greater than',
      db: 'greater than',
      ar: 'one of',
      str: 'one of',
    },
  }),
  created() {
    this.loadTags();
  },
  methods: {
    async loadTags() {
      const tags = await memberService.tags();
      this.$set(this, 'customerTagsNames', tags.map(e => e.tag));
      this.$set(this, 'customerTags', tags);
      if (this.field.dirty) {
        this.updateField(tags[0]);
      }
    },
    onValueChange() {
      if (this.field.internalKey === 'ar' || this.field.internalKey === 'str') {
        const valueMap = this.field.values.map(e => (this.field.operator === 'none of' ? `NOT "${e}"` : `"${e}"`));
        this.field.query = `(c_${this.field.key}:(${valueMap.join(' OR ')})) `;
        this.field.dirty = false;
      } else if (this.field.internalKey === 'ln' || this.field.internalKey === 'db') {
        if (this.field.operator === 'greater than' && this.field.from !== '') {
          this.field.query = `(c_${this.field.key}:[${this.field.from} TO *]) `;
          this.field.dirty = false;
        } else if (this.field.operator === 'less than' && this.field.to !== '') {
          this.field.query = `(c_${this.field.key}:[* TO ${this.field.to}]) `;
          this.field.dirty = false;
        } else if (this.field.operator === 'between' && this.field.from !== '' && this.field.to !== '') {
          this.field.query = `(c_${this.field.key}:[${this.field.from} TO ${this.field.to}]) `;
          this.field.dirty = false;
        }
      } else if (this.field.internalKey === 'b') {
        this.field.query = `(c_${this.field.key}:${this.field.checked}) `;
        this.field.dirty = false;
      }
      this.$emit('value-update');
    },
    onTagChange() {
      const filteredTag = this.customerTags.filter(e => e.tag === this.field.key);
      this.updateField(filteredTag[0]);
    },
    updateField(selectedTag) {
      this.$set(this, 'selectedTag', selectedTag);
      const customFieldType = selectedTag.type;
      this.field.operator = this.typeOperators[customFieldType];
      this.field.key = selectedTag.tag;
      this.field.internalKey = customFieldType;
      if (customFieldType === 'ar' || customFieldType === 'str') {
        this.field.values = [];
      } else if (customFieldType === 'ln' || customFieldType === 'db') {
        this.field.to = undefined;
        this.field.from = undefined;
      } else if (customFieldType === 'b') {
        this.field.checked = false;
      }
    },
  },
};
</script>
