export const deviceTypes = [{
  value: 'IOS',
  text: 'IOS',
}, {
  value: 'Android',
  text: 'Android',
}, {
  value: 'Huawei',
  text: 'Huawei',
}, {
  value: 'macOS',
  text: 'Web',
},
];
