<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="body2-title">Margin</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Top">
          <b-input-group size="sm" append="px">
            <b-form-input type="number" v-model="element.margin.top"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Right">
          <b-input-group size="sm" append="px">
            <b-form-input type="number" v-model="element.margin.right"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Bottom">
          <b-input-group size="sm" append="px">
            <b-form-input type="number" v-model="element.margin.bottom"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Left">
          <b-input-group size="sm" append="px">
            <b-form-input type="number" v-model="element.margin.left"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'marginEditor',
  props: ['element'],
};
</script>
