<template>
  <b-container fluid class="text-center">
    <b-row class="text-left">
      <b-col class="bg-secondary pt-2" cols="4">
        <base-svg :svg="field.icon"></base-svg>
        <span class="pl-2">{{ field.fieldText }}</span>
      </b-col>
      <b-col cols="2">
        <b-form-select :options="operators" :value="field.operator" v-model="field.operator">
        </b-form-select>
      </b-col>
      <b-col cols="3">
        <b-input-group v-show="(this.field.operator === 'before') || (this.field.operator === 'between')">
          <b-input placeholder="Before" no-wheel v-model="field.from" type="number" @blur="onValueChange"></b-input>
          <template #append>
            <b-form-select class="ml-2" :options="unitOptions" :value="field.fromUnit" v-model="field.fromUnit"
                           @change="onValueChange">
            </b-form-select>
          </template>
        </b-input-group>
      </b-col>
      <b-col cols="3">
        <b-input-group v-show="(this.field.operator === 'after') || (this.field.operator === 'between')">
          <b-input placeholder="After" no-wheel v-model="field.to" type="number" @blur="onValueChange"></b-input>
          <template #append>
            <b-form-select class="ml-2"
                           :options="unitOptions"
                           :value="field.toUnit"
                           v-model="field.toUnit" @change="onValueChange">
            </b-form-select>
          </template>
        </b-input-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

export default {
  name: 'RelativeDateTypeFilterElement',
  components: {},
  props: ['field'],
  data: () => ({
    unitOptions: ['minutes ago', 'hours ago', 'days ago', 'weeks ago'],
    operators: ['after', 'before', 'between'],
  }),
  methods: {
    onValueChange() {
      if (this.field.operator === 'before' && this.field.from && this.field.from !== '') {
        this.field.query = `(${this.field.key}:[* TO now-${this.field.from}${this.field.fromUnit.substring(0, 1)}]) `;
        this.$emit('value-update');
      } else if (this.field.operator === 'after' && this.field.to && this.field.to !== '') {
        this.field.query = `(${this.field.key}:[now-${this.field.to}${this.field.toUnit.substring(0, 1)} TO *]) `;
        this.$emit('value-update');
      } else if (this.field.operator === 'between' && this.field.to && this.field.from !== '' && this.field.to && this.field.to !== '') {
        this.field.query = `(${this.field.key}:[now-${this.field.from}${this.field.fromUnit.substring(0, 1)} TO now-${this.field.to}${this.field.toUnit.substring(0, 1)}]) `;
        this.$emit('value-update');
      }
    },
  },
};
</script>
