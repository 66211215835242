import { xenn } from './index';

export default {
  list(params) {
    return xenn.get('/assets', { params: params });
  },
  delete(id) {
    return xenn.delete(`/assets/${id}`);
  },
  post(options) {
    const fd = new FormData();
    Object.keys(options).forEach(key => fd.append(key, options[key]));
    return xenn.post('/assets', fd);
  },
  importFrom(options) {
    return xenn.post('/assets/import-from-url', options);
  },
};
