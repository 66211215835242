<template>
  <div>
    <modal modal-classes="modal-info" modal-content-classes="bg-gradient-info" :show.sync="modalVisibility" size="xl"
      body-classes="p-0">
      <card type="white" header-classes="bg-transparent" body-classes="px-lg-4 py-lg-4" class="border-0 mb-0">
        <template slot="header">
          <h3 class="mb-0">Segment List</h3>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-size="sm" class="mt-3 mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filters.name" placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button :disabled="filters.name === ''" variant="primary" @click="listSegments">Search</b-button>
                    <b-button variant="info" @click="clearFilter">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
        <el-table ref="segmentList" :data="tableData" row-key="id" @selection-change="selectionChange"
          @row-click="rowClick" class="table-compact" header-row-class-name="thead-light">
          <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" />
        </el-table>
        <b-card-footer class="justify-content-end pb-0">
          <b-row>
            <b-col>
              <base-pagination align="center" class="pagination-no-border mb--3" :current="pagination.currentPage"
                :per-page="pagination.perPage" :total="pagination.total" @change="paginationChanged($event)">
              </base-pagination>
            </b-col>
          </b-row>
        </b-card-footer>
      </card>
      <template slot="footer">
        <base-button type="primary" @click="completeSelection">Select Segments</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import segmentService from '../../services/segment';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Option, Select } from 'element-ui';
import clientPaginationMixin from '@/views/Tables/PaginatedTables/clientPaginationMixin';

export default {
  name: 'segmentListPopup',
  props: ['selectedSegments'],
  emits: ['openEvent'],
  mixins: [clientPaginationMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data: () => {
    return {
      disabledRowIds: [],
      modalVisibility: false,
      segments: [],
      filters: {
        name: '',
      },
      tableData: [],
      selectedRows: [],
      tableColumns: [{
        type: 'selection',
        'reserve-selection': true,
        selectable: (row) => !row.isDisable,
      }, {
        prop: 'name',
        label: 'Name',
        minWidth: 200,
        sortable: false
      }, {
        prop: 'pidCount',
        label: 'Audience Size',
        minWidth: 140,
        sortable: false
      }, {
        prop: 'pushTokenCount',
        label: 'Push',
        minWidth: 100,
        sortable: false
      }, {
        prop: 'emailCount',
        label: 'Email',
        minWidth: 120,
        sortable: false
      }, {
        prop: 'phoneCount',
        label: 'SMS',
        minWidth: 120,
        sortable: false
      }],
    };
  },
  watch: {
    modalVisibility(val) {
      if (!val) {
        this.pagination.currentPage = 1;
        this.clearFilter();
      }
    },
    'filters.name'() {
      this.pagination.currentPage = 1;
    }
  },
  methods: {
    selectRow(id, row) {
      this.$refs.segmentList.toggleRowSelection(id ? { id } : row, true);
    },
    toggleSelection() {
      if (this.$refs.segmentList) {
        this.selectedSegments.forEach(id => {
          this.selectRow(id);
        });
      }
    },
    handlePerPage(value) {
      this.pagination.perPage = value;
      this.listSegments();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.updateSelection();
      this.listSegments();
    },
    openEvent(disabledRowIds = []) {
      this.modalVisibility = true;
      this.listSegments();
      this.disabledRowIds = disabledRowIds;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows.map(segment => segment.id);
    },
    updateSelection() {
      this.$emit('on-segment-selected', this.selectedRows.filter((value, index, array) => array.indexOf(value) === index));
    },
    completeSelection() {
      this.updateSelection();
      this.modalVisibility = false;
    },
    clearFilter() {
      this.filters.name = '';
      this.filters.page = 1;
      this.listSegments();
    },
    async listSegments() {
      this.filters.size = this.pagination.perPage;
      this.filters.page = this.pagination.currentPage - 1;
      const response = await segmentService.list(this.filters);
      this.pagination.total = response.total;
      this.tableData = response.results.map(r => ({
        ...r,
        isDisable: this.disabledRowIds.includes(r.id),
      }));
      this.toggleSelection();
    },
    rowClick(row) {
      if (!row.isDisable) {
        this.selectRow(null, row);
      }
    },
  },
};
</script>
