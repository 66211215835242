import { xenn } from './index';

export default {
  get(params) {
    return xenn.get('/in-app-campaigns', { params: params });
  },
  getById(id) {
    return xenn.get(`/in-app-campaigns/${id}`);
  },
  update(id, params) {
    return xenn.put(`/in-app-campaigns/${id}`, params);
  },
  getStatisticsById(id, params) {
    return xenn.get(`/campaigns/${id}/campaign-statistics`, { params: params });
  },
  create(params) {
    return xenn.post('/in-app-campaigns', params);
  },
  delete(id) {
    return xenn.delete(`/in-app-campaigns/${id}`);
  },
  copy(id) {
    return xenn.post(`/in-app-campaigns/${id}/copy`);
  },
  approve(id) {
    return xenn.post(`/in-app-campaigns/${id}/approve`);
  },
  cancel(id) {
    return xenn.post(`/in-app-campaigns/${id}/cancel`);
  },
};
