<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col cols="5">
          <b-card no-body class="card-profile" img-src="img/theme/img-1-1000x600.jpg" alt="Image placeholder" img-top>
            <b-row class="justify-content-center">
              <b-col lg="3" class="order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <b-img src="img/theme/team-4.jpg" rounded="circle" />
                  </a>
                </div>
              </b-col>
            </b-row>

            <b-card-header class="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between">
                <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>
                <a href="#" class="btn btn-sm btn-default float-right">Message</a>
              </div>
            </b-card-header>

            <b-card-body class="pt-0">
              <b-row>
                <b-col >
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span class="heading">{{ subscriber.sc | formatNumeric }}</span>
                      <span class="description">Session Count</span>
                    </div>
                    <div>
                      <span class="heading">10</span>
                      <span class="description">Photos</span>
                    </div>
                    <div>
                      <span class="heading">89</span>
                      <span class="description">Comments</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="text-center">
                <h5 class="h3">
                  Jessica Jones<span class="font-weight-light">, 27</span>
                </h5>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>Bucharest, Romania
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>Solution Manager - Creative Tim Officer
                </div>
                <div>
                  <i class="ni education_hat mr-2"></i>University of Computer Science
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Profile</h6>
                <b-badge v-show="subscriber.tm">Test User</b-badge>
              </div>
            </template>
            <b-card-body>
              <b-list-group>
                <b-list-group-item>
                  <div v-if="application.applicationType !== 'WEB'">Device: {{ subscriber.br }} {{
                      subscriber.mn
                    }}
                  </div>
                  <div v-else>Device: {{ subscriber.os }} {{ subscriber.osv }}</div>
                </b-list-group-item>
                <b-list-group-item>Device Language: {{ subscriber.dl }}</b-list-group-item>
                <b-list-group-item>External Key: {{ subscriber.ek }}</b-list-group-item>
                <b-list-group-item>Email: {{ subscriber.em }}</b-list-group-item>
                <b-list-group-item>Phone: {{ subscriber.ph }}</b-list-group-item>
                <b-list-group-item>Ip: {{ subscriber.ip }}</b-list-group-item>
                <b-list-group-item>Session Count: {{ subscriber.sc | formatNumeric }}</b-list-group-item>
                <b-list-group-item>Time Zone: {{ subscriber.tz }}</b-list-group-item>
                <b-list-group-item>First Seen: {{ subscriber.fs | formatISODateTime }}</b-list-group-item>
                <b-list-group-item>Last Seen: {{ subscriber.ls | formatISODateTime }}</b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-card>
          <hr>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Tags</h6>
              </div>
            </template>
            <b-card-body>
              <b-list-group>
                <b-list-group-item v-for="item in Object.keys(subscriber).filter(a=>a.startsWith('c_'))" :key="item">
                  {{ item.replace('c_', '') }} : {{ subscriber[item] }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-card>
          <hr>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Segments ({{ subscriber.sm | formatArrayLengthSafe }})</h6>
              </div>
            </template>
            <b-card-body>
              <b-list-group>
                <b-list-group-item v-for="item in segments" :key="item.id">
                  {{ item.name }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Events</h6>
              </div>
            </template>
            <b-card-body>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import memberService from '../../services/members';
import segmentService from '../../services/segment';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';

export default {
  name: 'SubscriberDetail',
  computed: {
    ...mapGetters(['application']),
  },
  components: { RouteBreadCrumb, },
  data: () => ({
    subscriber: {},
    segments: [],
    isWeb: false,
  }),
  created() {
    this.subscriberDetail();
    this.breadCrumb[1].name = this.$route.params.id;
  },
  methods: {
    async subscriberDetail() {
      const subscriber = await memberService.subscriber(this.$route.params.id);
      debugger;
      const segments = await segmentService.getMulti(subscriber.sm);
      debugger;
      this.$set(this, 'subscriber', subscriber);
      this.$set(this, 'segments', segments.results);
    },
  },
};
</script>
