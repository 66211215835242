<template>
  <b-container fluid class="text-center">
    <b-row class="text-left">
      <b-col class="bg-secondary pt-2" cols="4">
        <base-svg :svg="field.icon"></base-svg>
        <span class="pl-2">{{ field.fieldText }}</span>
      </b-col>
      <b-col cols="8">
        <b-row>
          <b-col>
            <b-badge class="py-3">True</b-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ExistsTypeFilterElement',
  components: {},
  props: ['field'],
  data: () => ({}),
  created() {
    this.field.query = `(${this.field.key}:true)`;
    this.$emit('value-update');
  },
};
</script>
