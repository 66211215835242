<template>
  <div>
    <b-button size="sm" @click="showPickerVisibility = !showPickerVisibility"
              variant="outline">
      <b-icon-emoji-smile></b-icon-emoji-smile>
    </b-button>
    <div class="emoji-picker">
      <VEmojiPicker v-if="showPickerVisibility" @select="onEmojiSelected"/>
    </div>
    <div :class="showPickerVisibility? 'emoji-picker-back-drop': ''" @click="close()"></div>
  </div>
</template>
<script>

import { VEmojiPicker } from 'v-emoji-picker';

export default {
  name: 'emojiPicker',
  props: ['field', 'o'],
  data: () => ({
    showPickerVisibility: false,
  }),
  methods: {
    onEmojiSelected(selectedEmoji) {
      this.o[this.field] = this.o[this.field] + selectedEmoji.data;
    },
    close() {
      if (this.showPickerVisibility) {
        this.showPickerVisibility = false;
      }
    },
  },
  components: {
    VEmojiPicker,
  },
};
</script>
<style scoped>
.emoji-picker {
  position: absolute;
  display: inline-block;
  z-index: 10000;
}

.emoji-picker-back-drop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
}
</style>
