<template>
  <b-container fluid class="text-center">
    <b-row class="text-left">
      <b-col class="bg-secondary pt-2" cols="4">
        <base-svg :svg="field.icon"></base-svg>
        <span class="pl-2">{{ field.fieldText }}</span>
      </b-col>
      <b-col cols="2">
        <b-form-select :options="operators"
                       :value="field.operator"
                       v-model="field.operator"
        ></b-form-select>
      </b-col>
      <b-col cols="3">
        <b-input placeholder="From" no-wheel v-model="field.from" type="number"
                 v-show="(this.field.operator === 'greater than') || (this.field.operator === 'between')"
                 @blur="onValueChange"></b-input>
      </b-col>
      <b-col cols="3">
        <b-input placeholder="To" no-wheel v-model="field.to" type="number"
                 v-show="(this.field.operator === 'less than') || (this.field.operator === 'between')"
                 @blur="onValueChange"></b-input>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

export default {
  name: 'NumericTypeFilterElement',
  props: ['field'],
  data: () => ({
    operators: ['greater than', 'less than', 'between'],
  }),
  methods: {
    onValueChange() {
      if (this.field.operator === 'greater than' && this.field.from !== '') {
        this.field.query = `(${this.field.key}:[${this.field.from} TO *]) `;
        this.$emit('value-update');
      } else if (this.field.operator === 'less than' && this.field.to !== '') {
        this.field.query = `(${this.field.key}:[* TO ${this.field.to}]) `;
        this.$emit('value-update');
      } else if (this.field.operator === 'between' && this.field.from !== '' && this.field.to !== '') {
        this.field.query = `(${this.field.key}:[${this.field.from} TO ${this.field.to}]) `;
        this.$emit('value-update');
      }
    },
  },
};
</script>
