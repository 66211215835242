<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right">
          <base-button icon type="secondary" @click="onCreateNewSegment">
            <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
            <span class="btn-inner--text">Create Segment</span>
          </base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">Segment List</h3>
            </b-card-header>

            <el-table :data="tableData"
                      row-key="id"
                      header-row-class-name="thead-light">
              <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  v-bind="column"
              >
              </el-table-column>
              <el-table-column label="Last Refresh" prop="lastCalculatedDate" min-width="140">
                <template v-slot="{row}">
                  <!-- TODO: show local time. -->
                  {{ row.lastCalculatedDate | formatISODateTime }}
                </template>
              </el-table-column>
              <el-table-column prop="id">
                <template v-slot="{row}">
                  <el-dropdown trigger="click" class="dropdown">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                    <el-dropdown-menu class="dropdown-menu show" slot="dropdown">
                      <b-dropdown-item @click="onUpdateSegment(row.id)">
                        <b-icon-pencil></b-icon-pencil>
                        Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleDelete(row)">
                        <b-icon-trash></b-icon-trash>
                        Delete
                      </b-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>

            <b-card-footer class="justify-content-center">
              <b-row>
                <b-col lg="2">
                  <el-select @change="handlePerPage"
                             class="select-primary pagination-select"
                             v-model="pagination.perPage"
                             placeholder="Per page"
                  >
                    <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                  </el-select>
                </b-col>
                <b-col lg="5" offset="1">
                  <base-pagination align="center"
                                   class="pagination-no-border"
                                   :current="pagination.currentPage"
                                   :per-page="pagination.perPage"
                                   :total="pagination.total"
                                   @change="paginationChanged($event)"
                  >
                  </base-pagination>
                </b-col>
                <b-col lg="4" class="text-right">
                  <div class="">
                    <p class="card-category">
                      Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} Segments
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <SegmentModal ref="segment-modal" @segment-modal-close="closeModalWithoutNotice"
                  @segment-modal-open="onCreateNewSegment"
                  @segment-modal-complete="onSegmentModalComplete"></SegmentModal>
  </div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Option, Select } from 'element-ui';
import segmentService from '../../services/segment';
import SegmentModal from '../../components/segments/segmentModal.vue';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';
import clientPaginationMixin from '@/views/Tables/PaginatedTables/clientPaginationMixin';
import swal from 'sweetalert2';

export default {
  name: 'SegmentList',
  mixins: [clientPaginationMixin],
  components: {
    RouteBreadCrumb,
    SegmentModal,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
  },
  created() {
    this.listSegments();
  },
  mounted() {
    const segmentId = this.$route.params.id;
    if (segmentId) {
      this.$refs['segment-modal'].openSegmentModal(segmentId === 'create' ? undefined : segmentId);
    }
  },
  data: () => ({
    tableData: [],
    tableColumns: [
      {
        prop: 'name',
        label: 'Name',
        minWidth: 200,
        sortable: false
      },
      {
        prop: 'pidCount',
        label: 'Audience Size',
        minWidth: 140,
        sortable: false
      },
      {
        prop: 'pushTokenCount',
        label: 'Push',
        minWidth: 100,
        sortable: false
      },
      {
        prop: 'emailCount',
        label: 'Email',
        minWidth: 120,
        sortable: false
      },
      {
        prop: 'phoneCount',
        label: 'SMS',
        minWidth: 120,
        sortable: false
      },
    ],
  }),
  methods: {
    handlePerPage(value) {
      this.pagination.perPage = value;
      this.listSegments();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.listSegments();
    },
    closeModalWithoutNotice() {
      this.$router.push('/audience/segments');
      this.listSegments();
    },
    onSegmentModalComplete(segmentId) {
      this.$bvToast.toast(`Segment ${segmentId === 'create' ? 'Created' : 'Updated'} successfully`, {
        title: 'Segment Status',
        autoHideDelay: 10000,
      });
      this.closeModalWithoutNotice();
    },
    onItemCountChange(item) {
      this.size = item;
      this.listSegments();
    },
    onCreateNewSegment() {
      this.$router.push('/audience/segments/create');
      this.$refs['segment-modal'].openSegmentModal();
    },
    onUpdateSegment(id) {
      this.$router.push(`/audience/segments/${id}`);
      this.$refs['segment-modal'].openSegmentModal(id);
    },
    async listSegments() {
      const response = await segmentService.list({
        size: this.pagination.perPage,
        page: this.pagination.currentPage - 1
      });
      this.pagination.total = response.total;
      this.tableData = response.results;
    },
    async handleDelete(row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              segmentService.delete(row.id)
                  .then(() => {
                    this.listSegments();
                    swal.fire({
                      title: 'Deleted!',
                      text: `You deleted ${row.name}`,
                      type: 'success',
                      confirmButtonClass: 'btn btn-success btn-fill',
                      buttonsStyling: false
                    });
                  });
            }
          });
    },
  },
};
</script>
